<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  async mounted() {
    await this.prepareAxios();
    console.log('axios prepared')
  },
  methods: {
    async prepareAxios(){
       let msalApp = this.$store.state.msalApp;

       if (!msalApp) {
         return;
       }

      let accounts = msalApp.getAllAccounts();
      if (accounts.length > 0) {
        msalApp.setActiveAccount(accounts[0]);
        this.$store.commit('msalApp', msalApp);
      }

        let {accessToken} = await msalApp.acquireTokenSilent({scopes:[
            `https://${this.$store.state.logindata.DATAVERSE_DOMAIN}/.default`
        ]});
        this.token = accessToken
        let result = axios.create({
            baseURL:`https://${this.$store.state.logindata.DATAVERSE_DOMAIN}/api/data/${this.$store.state.logindata.DATAVERSE_VERSION}`,
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Authorization':'Bearer '+ accessToken,
                'Prefer': 'return=representation'
            }
        });
        this.$store.commit('axios', result);
        this.$store.state.axios.get("/GlobalOptionSetDefinitions(9e739642-91c1-ec11-983e-000d3ac88352)").then(({data})=>{
          let rooms = {}  
          data.Options.forEach(o=>{
            rooms[o.Value] = o.Label.UserLocalizedLabel.Label;
          })
          this.$store.commit('rooms', rooms);

        })

    }
  }
});
</script>