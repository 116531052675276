<template>
  <!-- <link rel="stylesheet" type="text/css" href="../theme/variables.css"/> -->
  <ion-page>

    <!-- check-in modal -->
    <ion-modal @did-dismiss="checkedInModal.show = false" :swipe-to-close="true" :is-open="checkedInModal.show"
      class="modalStyle" style="--width: 90%; --height: 90%;">
      <ion-content v-if="checkedInModal.show">

        <!-- <template v-if="!checkOutMode">
            <div v-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'valid'" class="divTest">
              <div>

              <h3 class="textStyleNormal text-center" style="margin-bottom:20px">Who is with you today?</h3>
              <ion-list>
                <ion-item v-for="(caregiver, ci) in checkedInModal.caregivers" :key="`caregiver_${ci}`" style="font-size:22px">
                  <ion-avatar class="avatarHolder" style="max-width:95px; height:90px;margin: 20px">
                    <ion-img :src="defaultUserImg"></ion-img>
                  </ion-avatar>
                  <ion-label class="textStyleBold"><strong>{{caregiver.name}}</strong></ion-label>
                  <ion-checkbox slot="end" @update:modelValue="caregiver.isChecked = $event"
                    :modelValue="caregiver.isChecked">
                  </ion-checkbox>
                </ion-item>
              </ion-list>
              <hr />
              <ion-button class="button_light_no_border" v-if="checkedInModal.caregivers.length <= 3"
                @click="addCaregiver(checkedInModal.client_id)" style="text-align: left;margin-top: 10px;">
                <ion-icon :icon="i.add"></ion-icon>New Caregiver</ion-button>
              </div>
              <div>
              <hr class="linebreak" style="margin-top:60px;margin-bottom:45px" />
              <h3 class="textStyleNormal" style="text-align: center; margin-bottom: 40px;">Check-in Details:</h3>
              <h3 class="textStyleNormal" style="font-size: 20px;text-align: left;">Session Name:
                <strong>{{checkedInModal.sessions_today?.[0]?.session_name}}</strong>
              </h3>
              <h2 class="textStyleNormal" style="font-size: 20px; text-align: left;">Room: <strong>{{checkedInModal.sessions_today?.[0]?.room}}</strong></h2>
              </div>
              <hr class="linebreak" style="margin-top:45px;margin-bottom:50px" />
              
              <ion-button class="keytemperature"
                @click="confirmCheckIn(checkedInModal.sessions_today.length ? checkedInModal.sessions_today?.[0]?.id: null)"
                style="margin:auto">
                Check-In</ion-button>
              
            </div>
            <div v-else-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'early'">
              <h2>Check-in only allowed an hour before the session</h2>
            </div>
            <div v-else-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'late'">
              <h2>Unable to check-in, session has ended</h2>
            </div>

            <div v-else-if="checkedInModal.sessions_today.length > 1">Many</div>
            <div v-else-if="checkedInModal.sessions_today_all.length">You have already checked in.</div>
            <div v-else-if="(checkedInModal.sessions_today_all.length < 1 || checkedInModal.sessions_today.length === 1) 
            && checkedInModal.clientStatus == 411620002">
              <h2>Client is already DISCHARGED</h2>
              <hr />
              <hr />
            </div>
            <div v-else-if="(checkedInModal.sessions_today_all.length < 1 || checkedInModal.sessions_today.length === 1) 
            && checkedInModal.clientStatus == 411620003">
              <h2>Unable to check-in, client is INACTIVE</h2>
              <hr />
              <hr />
            </div>
            <div v-else-if="checkedInModal.sessions_today_all.length <1 && checkedInModal.clientStatus == 411620001">
              <h2>Client status is still PENDING</h2>
              <hr />
              <hr />
            </div>
            <div v-else>
              <h2>You are not scheduled to check-in today!</h2>
              <hr />
              <hr />
              <div class="center">
                <ion-button style="width:100%;margin:auto;" class="button_light" @click="showAdHocList">Add to an adhoc
                  session</ion-button>
              </div>

            </div>
          </template> -->

        <template v-if="!checkOutMode">
          <div class="modalStyleModified__contentHolder"
            v-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'valid'">
            <!----------------------------- TITLE ----------------------------->

            <div class="modalStyleModified__title">
              <ion-row>
                <ion-col size="3" style="max-width:90px"><ion-img :src="dsgCheckInTitleImg"
                    class="modalStyleModified__dsgTitlePicture"></ion-img></ion-col>
                <ion-col>
                  <h1 class="modalStyleModified__titleWord">Welcome to Dementia Singapore Family of Wisdom!</h1>
                </ion-col>
              </ion-row>
            </div>
            <!----------------------------- TITLE ----------------------------->

            <div class="modalStyleModified__main">
              <div class="modalStyleModified__colOne">
                <div class="modalStyleModified__nameAndAvatarClient">
                  <div class="modalStyleModified__clientAvatar">
                    <ion-avatar class="modalStyleModified__avatarHolder">
                      <ion-img :src="defaultUserImg"></ion-img>
                    </ion-avatar>
                  </div>
                  <div class="modalStyleModified__clientNameHolder">
                    <span class="modalStyleModified__clientName">{{ checkedInModal.client_name }}</span>
                  </div>
                </div>
                <div class="modalStyleModified__detailsClient">
                  <div class="modalStyleModified__oneDetailsClient">
                    <span class="modalStyleModified__wordDetails">Check-in details: </span>
                  </div>
                  <div class="modalStyleModified__twoDetailsClient">
                    <ion-grid id="config-table" class="modalStyleModified__tableSessionHolder">
                      <ion-row class="modalStyleModified__headerItemSession">
                        <ion-col class="header" size="8">Session Name</ion-col>
                        <ion-col class="header" size="4">Room no</ion-col>
                      </ion-row>

                      <ion-row class="modalStyleModified__itemSession">
                        <ion-col class="body" size="8"
                          style="border-right: 1px solid #000;">{{ checkedInModal.sessions_today?.[0]?.session_name }}</ion-col>
                        <ion-col class="body" size="4">{{ checkedInModal.sessions_today?.[0]?.room }}</ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                </div>
              </div>
              <div class="modalStyleModified__colTwo">
                <div class="modalStyleModified__oneCareGiver">
                  <div class="modalStyleModified__holderWordCareGiver">
                    <span class="modalStyleModified__wordCaregiver">Who's with you today?</span>
                  </div>
                  <div class="modalStyleModified__btnCaregiverHolder" v-if="checkedInModal.caregivers.length <= 3"
                    @click="addCaregiver(checkedInModal.client_id)">
                    <!-- icon square-add -->
                    <ion-icon class="modalStyleModified__btnCaregivers" :icon="i.squareOutline"></ion-icon>
                    <ion-icon class=" modalStyleModified__btnCaregivers--addIcon" :icon="i.add"></ion-icon>
                    <!-- icon square-add -->

                    <span class="modalStyleModified__wordBtnCaregiver">New Caregiver</span>
                  </div>
                </div>
                <div class="modalStyleModified__twoCareGiver">
                  <ion-list class="modalStyleModified__holderItemCaregiver">

                    <ion-item class="modalStyleModified__itemCaregiver"
                      v-for="(caregiver, ci) in checkedInModal.caregivers" :key="`caregiver_${ci}`">
                      <ion-label class="modalStyleModified__holderAvatarAndNameCaregiver"
                        style="display: flex; gap: 1rem;  align-items: center;">
                        <ion-avatar class="modalStyleModified__avatarCaregiver">
                          <ion-img :src="defaultUserImg"></ion-img>
                        </ion-avatar>
                        <span class="modalStyleModified__caregiverName">{{ caregiver.name }} </span>
                      </ion-label>
                      <ion-checkbox class="modalStyleModified__btnSelectCaregiver" slot="end"
                        style="margin-right: 1rem;" @update:modelValue="caregiver.isChecked = $event"
                        :modelValue="caregiver.isChecked"></ion-checkbox>
                    </ion-item>

                  </ion-list>

                </div>
              </div>
            </div>
            <div class="modalStyleModified__btnHolder">
              <ion-button class="modalStyleModified__btnCheckIn " shape="round" style="height: 3rem;"
                @click="confirmCheckIn(checkedInModal.sessions_today.length ? checkedInModal.sessions_today?.[0]?.id : null)">Check-in</ion-button>
            </div>
          </div>

          <div class="modalStyleModified__contentHolder--cannotCheckIn" v-else>
            <div class="modalStyleModified__title">
              <ion-img :src="dsgCheckInTitleImg" class="modalStyleModified__dsgTitlePicture"></ion-img>
              <div style="position: absolute;margin-left: 27%;margin-top:20px">
                <h1 class="modalStyleModified__titleWord">Welcome to Dementia Singapore Family of Wisdom!</h1>
              </div>
            </div>

            <div class="modalStyleModified__holderCannotCheckInTitle">
              <div v-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'early'">
                <div style="text-align: center; margin-bottom: 20px;">
                  <ion-icon class="iconLabel" :icon="i.lockClosed"></ion-icon>
                </div>
                <h1 class="modalStyleModified__titleWord">Check-in only allowed an hour before the session</h1>
              </div>

              <div v-else-if="checkedInModal.sessions_today.length === 1 && checkedInModal.validCheckinTime == 'late'">
                <div style="text-align: center; margin-bottom: 20px;">
                  <ion-icon class="iconLabel" :icon="i.lockClosed"></ion-icon>
                </div>
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">Unable to
                  check-in, session has ended.</h1>
              </div>

              <div v-else-if="checkedInModal.sessions_today.length > 1">
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">Many</h1>
              </div>

              <div v-else-if="checkedInModal.sessions_today_all.length">
                <div style="text-align: center; margin-bottom: 20px;">
                  <ion-icon class="iconLabel" :icon="i.checkmarkDone"></ion-icon>
                </div>
                <h1 v-if="this.checkedInModal.sessions_today_checkout.length"
                  class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">You have
                  already checked in.</h1>
                <h1 v-else class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">You
                  have already checked out.</h1>
              </div>

              <div v-else-if="(checkedInModal.sessions_today_all.length < 1 || checkedInModal.sessions_today.length === 1)
              && checkedInModal.clientStatus == 411620002">
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">Client is
                  already DISCHARGED</h1>
              </div>

              <div v-else-if="(checkedInModal.sessions_today_all.length < 1 || checkedInModal.sessions_today.length === 1)
              && checkedInModal.clientStatus == 411620003">
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">Unable to
                  check-in, client is INACTIVE</h1>
              </div>

              <div v-else-if="checkedInModal.sessions_today_all.length < 1 && checkedInModal.clientStatus == 411620001">
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">Client
                  status is still PENDING</h1>
              </div>

              <div v-else>
                <div style="text-align: center; margin-bottom: 20px;">
                  <ion-icon class="iconLabel" :icon="i.alertCircle"></ion-icon>
                </div>
                <h1 class="modalStyleModified__titleWord modalStyleModified__titleWord--titleCannotCheckIn">You are not
                  scheduled to check-in today!</h1>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="checked-in-modal-content-container">
            <h1 class="text-center" style="margin-bottom:20px" v-if="!checkOutMode">Hi <strong>{{
                checkedInModal.client_name
            }}</strong><br /><small>Welcome to Dementia Singapore Family of Wisdom</small></h1>
            <h1 class="text-center" style="margin-top:60px" v-else> You are about to check-out<br /><br /></h1>
            <hr />
            <div style="margin: auto;display: flex;justify-content: center;">
              <ion-avatar class="avatarHolder">
                <ion-img :src="defaultUserImg"></ion-img>
              </ion-avatar>
            </div>
            <h2 class="checkoutlable"><strong>{{ checkedInModal.client_name
            }}</strong></h2>
            <hr />
            <div v-if="checkOutMode">
              <div v-if="checkedInModal.sessions_today_checkout.length === 1"
                style="text-align: center;font-size: 16px;margin-top: 20px;">
                <hr />
                <hr />
                <hr />
                <hr />
                <div>
                  <ion-row>
                    <ion-col>
                      <ion-button class="keytemperature" style="width:500px; margin:auto;"
                        @click="doMainCheckOut(checkedInModal.sessions_today_checkout.length ? checkedInModal.sessions_today_checkout?.[0]?.id : null)">
                        <ion-icon style="margin-right:20px;" :icon="i.logOut"></ion-icon>
                        Checkout</ion-button>
                    </ion-col>
                    <ion-col v-if="!haveToFillInTemp">
                      <ion-button class="button_light checkoutTempLable" style="margin:auto; margin-bottom: 45px;"
                        @click="openTempModal(this.checkedInModal.sessions_today_checkout?.[0]?.id)">
                        <ion-icon style="margin-right:10px;" :icon="i.thermometer"></ion-icon>
                        Key-in Temperature</ion-button>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
              <div class="checkoutMessage" v-else-if="checkedInModal.sessions_today_checkout.length > 1">
                Many checkout
                <!-- <div class="center">
                <ion-button class="ToolbarBtn">Checkout</ion-button>
              </div> -->
              </div>
              <div class="checkoutMessage"
                v-else-if="checkedInModal.sessions_today_checkout.length < 1 && checkedInModal.sessions_today.length === 1">
                You are not checked-in yet, please proceed with check-in.
              </div>
              <div v-else class="checkoutMessage">
                You already checked out.
              </div>
            </div>
          </div>
        </template>
      </ion-content>
    </ion-modal>

    <ion-modal @did-dismiss="manualCheckedInModal.show = false" @did-present="focusInput('nriccaraid')"
      :swipe-to-close="true" :is-open="manualCheckedInModal.show">
      <ion-content v-if="manualCheckedInModal.show">
        <div class="checked-in-modal-content-container">
          <h1 class="text-center">Enter your NRIC below or scan your NRIC ID card</h1>
          <h3>
            <ion-input ref="nriccaraid" inputmode="search" v-model="manualCheckedInModal.id"></ion-input>
          </h3>
          <div class="center">
            <ion-button class="ToolbarBtn" :disabled="!validNric" @click="showClient(manualCheckedInModal.id, false)">
              Manual Check-{{ checkOutMode ? 'Out' : 'In' }}</ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>
    <ion-modal class="tempModal" @did-dismiss="tempModal.show = false" @did-present="focusInput('clienttemp')"
      :swipe-to-close="true" :is-open="tempModal.show">
      <ion-content v-if="tempModal.show">
        <div class="checked-in-modal-content-container">
          <h1 style="font-weight: bold;">Client Temperature</h1>
          <h4 class="tempLabel" style="margin-top: 35px;margin-bottom: 30px;">Enter the temperature for client</h4>
          <div style="width: 100%;" class="d-flex ion-justify-content-center">
            <ion-grid class="ion-padding">
              <ion-row class="centerize-cols row-color-purple">
                <ion-col>
                  <strong>
                    <div style="text-align: center;">Temperature In</div>
                  </strong>
                </ion-col>
                <ion-col>
                  <strong>
                    <div style="text-align: center;">Temperature Out</div>
                  </strong>
                </ion-col>
              </ion-row>
              <ion-row class="centerize-cols">
                <ion-col>
                  <h3>
                    <ion-input ref="clienttemp" type="number" step="0.1" v-model="tempModal.clientTemp"
                      class="textStyleNormal2" :class="{ 'textStyleDanger': tempModal.clientTemp > 37.5 }"></ion-input>
                  </h3>
                </ion-col>
                <ion-col>
                  <h3>
                    <ion-input type="number" step="0.1" v-model="tempModal.clientOutTemp" class="textStyleNormal2"
                      :class="{ 'textStyleDanger': tempModal.clientOutTemp > 37.5 }"></ion-input>
                  </h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <!-- <div class="checked-in-modal-content-container">
          <h4 style="padding-left:30px;padding-right:30px">Enter the temperature upon check-out (can leave blank and be filled in later upon checkout)</h4>
          
        </div> -->
        <template v-if="tempModal.activeClientSessionDetails.crb5c_caregiver">
          <hr class="divider" />
          <div class="checked-in-modal-content-container">
            <h1 style="font-weight: bold;">Caregiver Temperature</h1>
            <h4 class="tempLabel" style="margin-top: 35px;">Enter the temperature for caregiver(s)</h4>
          </div>
          <div style="width: 96%;margin: auto;" class="d-flex ion-justify-content-center">
            <ion-grid class="ion-padding">
              <ion-row class="centerize-cols row-color-purple">
                <ion-col>
                  <strong>
                    <div style="text-align: center;">Name</div>
                  </strong>
                </ion-col>
                <ion-col>
                  <strong>
                    <div style="text-align: center;">Temperature In</div>
                  </strong>
                </ion-col>
                <ion-col>
                  <strong>
                    <div style="text-align: center;">Temperature Out</div>
                  </strong>
                </ion-col>
              </ion-row>
              <ion-row class="centerize-cols" v-for="(c, ci) in tempModal.caregiversAndTemps"
                :key="`caregiver_temp_${ci}`">
                <ion-col>
                  <div style="text-align: center;">{{ c.name }}</div>
                </ion-col>
                <ion-col>
                  <h3>
                    <ion-input type="number" step="0.1" v-model="c.temp" :class="{ 'textStyleDanger': c.temp > 37.5 }">
                    </ion-input>
                  </h3>
                </ion-col>
                <ion-col>
                  <h3>
                    <ion-input type="number" step="0.1" v-model="c.tempOut"
                      :class="{ 'textStyleDanger': c.tempOut > 37.5 }">
                    </ion-input>
                  </h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </template>
        <div class="checked-in-modal-content-container">
          <ion-button class="ToolbarBtn" :disabled="allTempsFilledIn" @click="submitTemp">Submit</ion-button>
        </div>
      </ion-content>
    </ion-modal>
    <ion-modal :swipe-to-close="true" :is-open="registrationModal.show">
      <ion-content>
        <div class="checked-in-modal-content-container">
          <h1 class="text-center">Registration Form</h1>
          <div>
            <ion-button class="ToolbarBtn" :disabled="validRegistrationForm" @click="confirmRegister">Fill in with
              MyInfo</ion-button>
          </div>
          <hr style="margin-top: 10px; border-top: 1px solid black; width: 100%;" />
          <h3>
            <label>Name of client:</label>
            <ion-input ref="nriccaraid" v-model="manualCheckedInModal.id"></ion-input>
          </h3>
          <h3>
            <div class="apart">
              <label>Name of caregivers:</label>
              <small style="margin-left: 20px;">
                <ion-button class="ToolbarBtn" :disabled="registrationModal.caregivers.length >= 4"
                  @click="addCaregivers">+</ion-button>
              </small>
            </div>
            <ion-list>
              <ion-item class="apart" v-for="(c, ri) in registrationModal.caregivers" :key="'reg_' + ri">
                <ion-label>
                  {{ c }}
                </ion-label>
                <ion-button class="ToolbarBtn" @click="registrationModal.caregivers.splice(ri, 1)">X</ion-button>
              </ion-item>
            </ion-list>
          </h3>
          <h3>
            <label>NRIC of client:</label>
            <ion-input></ion-input>
          </h3>
          <h3>
            <label>Email of client:</label>
            <ion-input></ion-input>
          </h3>
          <h3>
            <label>Contact Number of client:</label>
            <ion-input></ion-input>
          </h3>
          <h3>
            <label>Address of client:</label>
            <ion-input v-model="manualCheckedInModal.id"></ion-input>
          </h3>
          <div class="center">
            <ion-button class="ToolbarBtn" :disabled="validRegistrationForm" @click="confirmRegister">Register
            </ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>


    <ion-modal class="my-modal" :swipe-to-close="false" :is-open="highTempModal.show">
      <ion-content>
        <h3 class="alert-title">High temperature alert!</h3>
        <h2 class="text-left">Temperature recorded for check-in is higher than 37.5 degrees, pick an option to auto fill
          report and cancel session:</h2>
        <div class="centerList" style="margin-left:60px;margin-right:70px;">
          <hr />
          <hr />
          <div class="optionList" style="margin-left:10px;margin-right:10px;">
            <ion-item>
              <ion-label class="itemFont"><strong>Option 1: </strong>Staff attended to client until main caregiver
                arrived</ion-label>
              <ion-button class="button_light_small" @click="TempCheck(tempModal.attendanceId, 0)">Select</ion-button>
            </ion-item>
            <ion-item>
              <ion-label class="itemFont"><strong>Option 2: </strong>Secondary caregiver attended to client until main
                caregiver arrived</ion-label>
              <ion-button class="button_light_small" @click="TempCheck(tempModal.attendanceId, 1)">Select</ion-button>
            </ion-item>
            <ion-item>
              <ion-label class="itemFont"><strong>Option 2: </strong>Main caregiver was on site and picked up the client
                immediately</ion-label>
              <ion-button class="button_light_small" @click="TempCheck(tempModal.attendanceId, 2)">Select</ion-button>
            </ion-item>
          </div>
          <div class="center" style="margin-top:70px;">
            <ion-button class="button_light_cancel" style="width:30%;margin-top:30px;margin: auto;"
              @click="this.closeModal">Cancel</ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>

    <ion-modal class="my-modal" :swipe-to-close="false" :is-open="crHighTempModal.show">
      <ion-content>
        <h3 class="alert-title">Caregiver with HIGH temperature!</h3>
        <h2 class="text-left">Temperature recorded for caregiver during check-in is high, pick or add a replacement
          caregiver that will join/pick up the client</h2>
        <div class="centerList" style="margin-left:60px;margin-right:70px;">
          <hr />
          <hr />
          <div class="center" style="margin-bottom:15px;">
            <ion-button slot="end" class="button_light_small_3" :disabled="maxCg" @click="this.newCaregiver">+ Add
              Caregiver</ion-button>
          </div>
          <div v-if="crHighTempModal.reserveCaregivers.length != 0" class="optionList"
            style="margin-left:10px;margin-right:10px;">
            <!-- <ion-item v-for="(caregiver, index) in crHighTempModal.reserveCaregivers" :key="index">
          <ion-label><strong>{{caregiver.name}}</strong></ion-label>
          <ion-button class="button_light_small" v-on:click="changeCaregiver(caregiver)">Choose</ion-button>
          </ion-item> -->
            <ion-list class="centerItems">
              <ion-item v-for="(caregiver, index) in crHighTempModal.reserveCaregivers" :key="`caregiver_${index}`">
                <ion-avatar class="avatarHolder" style="max-width:75px; height:70px;margin: 15px">
                  <ion-img :src="defaultUserImg"></ion-img>
                </ion-avatar>
                <ion-label class="textStyleBold"><strong>{{ caregiver.name }}</strong></ion-label>
                <ion-checkbox slot="end" @update:modelValue="caregiver.isChecked = $event"
                  :modelValue="caregiver.isChecked">
                </ion-checkbox>
              </ion-item>
            </ion-list>
          </div>
          <div v-else>
            <h2 class="text-left-small">Click on the "Add Caregiver" to add a new caregiver to pick as a replacement
            </h2>
          </div>
          <div class="center" style="margin-top:50px;">
            <ion-button class="button_light" style="width:30%;margin-top:30px;margin: auto;"
              @click="this.changeCaregiver">Proceed</ion-button>
          </div>
          <div class="center" style="margin-top:30px;">
            <ion-button class="button_light_cancel" style="width:30%;margin-top:30px;margin: auto;"
              @click="this.closeModal">Cancel</ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>

    <ion-modal class="my-modal" :swipe-to-close="true" :is-open="emergencyModal.show" backdropDismiss="false">
      <ion-content>
        <h3 class="alert-title">Incident report!</h3>
        <h2 class="text-left">Description:</h2>
        <div class="incidentReport">
          <ion-item>
            <ion-textarea placeholder="Enter more information here..."
              @input="emergencyModal.areaInput = $event.target.value"></ion-textarea>
          </ion-item>
        </div>
        <div class="center" style="margin-top:150px;">
          <ion-button class="button_strong" style="width:30%;margin-top:100px;margin: auto;"
            :disabled="incidentReportFilled" @click="this.EmergencyReport(emergencyModal.sessID)">Submit</ion-button>
        </div>
        <div class="center" style="margin-top:30px;">
          <ion-button class="button_light_cancel" style="width:30%;margin-top:100px;margin: auto;"
            @click="this.closeModal()">Close</ion-button>
        </div>
      </ion-content>
    </ion-modal>


    <ion-modal :is-open="navigateToAttendanceModal.show" @did-dismiss="navigateToAttendanceModal.show = false">
      <ion-content>
        <div class="checked-in-modal-content-container">
          <ion-icon style="margin-top:50px;margin-bottom:20px;" class="icon_logo" :icon="i.checkboxOutline"></ion-icon>
          <h3 style="margin-top:30px;margin-bottom:20px;">Session report is already recorded for this client </h3>
          <div class="center link-button">
            <a style="color:white; text-decoration:none" target="_BLANK"
              :href="`https://dsg-fow-dev.crm5.dynamics.com/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entityrecord&etn=crb5c_fowsessionattendance&id=${this.sessionReportModal.sessID}`">View
              Session Report</a>
          </div>
        </div>

      </ion-content>
    </ion-modal>

    <!-- Session Report mild -->
    <ion-modal ref="mildModal" class="my-modal-large" :is-open="sessionReportModal.reportType == 0"
      backdropDismiss="false">
      <ion-content>
        <ion-button class="modalCloseButton" @click="confirmExitModal">
          <ion-icon :icon="i.close"></ion-icon>
        </ion-button>
        <div class="flex-wrap-override">
          <ion-row>
            <ion-col>
              <h3 class="title_left_medium">{{ this.sessionReportModal.clientDetail.crb5c_client.crb5c_no }}'s Session
                Report</h3>
            </ion-col>
            <ion-col class="col-right">
              <ion-label class="title_left_small">Date: {{ this.sessionReportModal.date.format("DD/MM/YYYY") }}
                &nbsp;&nbsp;
                Time: {{ this.sessionReportModal.date.format("hh:mm A") }}</ion-label>
            </ion-col>
          </ion-row>

          <div class="reportHeader">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Mood on Arrival:</h3>
                <v-select v-model="this.mildReport.moodOnArrivalMild" :options="moodOptions" multiple
                  :reduce="moodOptions => moodOptions.id">
                  <template v-slot:option="moodOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
              <ion-col>
                <h3 class="title_left_normal_report">Behaviour on Arrival:</h3>
                <v-select v-model="this.mildReport.behaviourOnArrivalMild" :options="behaviourOptions" multiple
                  :reduce="behaviourOptions => behaviourOptions.id">
                  <template v-slot:option="behaviourOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ behaviourOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
            </ion-row>
            <ion-label v-if="!arrivalFilled" class="instructions">**Complete this section to go to activity selection
            </ion-label>
          </div>

          <hr class="lineBreak" />

          <StickyElement stickMode="element-start" class="stickyBtn">
            <ion-button @click="vClear">Save draft</ion-button>
          </StickyElement>

          <div v-if="arrivalFilled" class="itemss">
            <div style="margin-top: 30px;margin-bottom: 40px;text-align: center;">
              <ion-label class="title_center_report">Activity 1 (Physical)</ion-label>
            </div>
            <div style="margin-bottom: 30px;">
              <ion-label class="title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
            </div>
            <ion-row class="center-row">
              <ion-item v-for="(games, gm) in sessionReportModal.physicalActivities" :key="`${gm}`" item-left
                lines="none">
                <ion-card class="activity_card">
                  <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                  <div class="activityCheckbox">
                    <ion-radio-group radio-group v-model="this.mildReport.activities.one.name"
                      @ionChange="checkActivity1Name">
                      <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                    </ion-radio-group>
                    <ion-label
                      style="font-size: 14px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                      {{ games.crb5c_name }}</ion-label>
                  </div>
                </ion-card>
              </ion-item>
            </ion-row>

            <div class="reportSelectOptions">
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Behaviour:</h3>
                  <v-select v-model="this.mildReport.activities.one.behaviour" :options="behaviourOptions" multiple
                    :reduce="behaviourOptions => behaviourOptions.id">
                    <template v-slot:option="behaviourOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Mood:</h3>
                  <v-select v-model="this.mildReport.activities.one.mood" :options="moodOptions" multiple
                    :reduce="moodOptions => moodOptions.id">
                    <template v-slot:option="moodOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Thoughts:</h3>
                  <v-select v-model="this.mildReport.activities.one.thought" :options="thoughtOptions" multiple
                    :reduce="thoughtOptions => thoughtOptions.id">
                    <template v-slot:option="thoughtOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Participation:</h3>
                  <v-select v-model="this.mildReport.activities.one.participation" :options="participationOptions"
                    multiple :reduce="participationOptions => participationOptions.id">
                    <template v-slot:option="participationOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                  <v-select v-model="this.mildReport.activities.one.alertness" :options="levelofAlertOptions" multiple
                    :reduce="levelofAlertOptions => levelofAlertOptions.id">
                    <template v-slot:option="levelofAlertOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Attention Span:</h3>
                  <v-select v-model="this.mildReport.activities.one.attention" :options="attentionspanOptions" multiple
                    :reduce="attentionspanOptions => attentionspanOptions.id">
                    <template v-slot:option="attentionspanOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true" @input="this.mildReport.activities.one.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-label v-if="!activity1Filled" class="instructions">**Complete activity 1 to proceed to next activity
              </ion-label>
            </div>
            <hr class="lineBreak" />
          </div>

          <div v-if="activity1Filled" class="itemss">
            <div style="margin-bottom: 30px;">
              <div style="margin-top: 60px;margin-bottom: 40px;text-align: center;">
                <ion-label class="title_center_report">Activity 2 (Psycho-socio)</ion-label>
              </div>
              <ion-label class="title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
            </div>
            <ion-row class="center-row">
              <ion-item v-for="(games, gm) in sessionReportModal.psysioActivities" :key="`${gm}`" item-left lines="none">
                <ion-card class="activity_card">
                  <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                  <div class="activityCheckbox">
                    <ion-radio-group radio-group v-model="this.mildReport.activities.two.name"
                      @ionChange="checkActivity1Name">
                      <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                    </ion-radio-group>
                    <ion-label
                      style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                      {{ games.crb5c_name }}</ion-label>
                  </div>
                </ion-card>
              </ion-item>
            </ion-row>

            <div class="reportSelectOptions">
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Behaviour:</h3>
                  <v-select v-model="this.mildReport.activities.two.behaviour" :options="behaviourOptions" multiple
                    :reduce="behaviourOptions => behaviourOptions.id">
                    <template v-slot:option="behaviourOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Mood:</h3>
                  <v-select v-model="this.mildReport.activities.two.mood" :options="moodOptions" multiple
                    :reduce="moodOptions => moodOptions.id">
                    <template v-slot:option="moodOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Thoughts:</h3>
                  <v-select v-model="this.mildReport.activities.two.thought" :options="thoughtOptions" multiple
                    :reduce="thoughtOptions => thoughtOptions.id">
                    <template v-slot:option="thoughtOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Participation:</h3>
                  <v-select v-model="this.mildReport.activities.two.participation" :options="participationOptions"
                    multiple :reduce="participationOptions => participationOptions.id">
                    <template v-slot:option="participationOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                  <v-select v-model="this.mildReport.activities.two.alertness" :options="levelofAlertOptions" multiple
                    :reduce="levelofAlertOptions => levelofAlertOptions.id">
                    <template v-slot:option="levelofAlertOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Attention Span:</h3>
                  <v-select v-model="this.mildReport.activities.two.attention" :options="attentionspanOptions" multiple
                    :reduce="attentionspanOptions => attentionspanOptions.id">
                    <template v-slot:option="attentionspanOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true" @input="this.mildReport.activities.two.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-label v-if="!activity2Filled" class="instructions">**Complete activity 2 to proceed to next activity
              </ion-label>
            </div>
            <hr class="lineBreak" />
          </div>

          <div v-if="activity2Filled" class="itemss">
            <div style="margin-bottom: 30px;">
              <div style="margin-top: 60px;margin-bottom: 40px;text-align: center;">
                <ion-label class="title_center_report">Activity 3 (Cognitive)</ion-label>
              </div>
              <ion-label class="title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
            </div>
            <ion-row class="center-row">
              <ion-item v-for="(games, gm) in sessionReportModal.cognitiveActivities" :key="`${gm}`" item-left
                lines="none">
                <ion-card class="activity_card">
                  <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                  <div class="activityCheckbox">
                    <ion-radio-group radio-group v-model="this.mildReport.activities.three.name"
                      @ionChange="checkActivity1Name">
                      <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                    </ion-radio-group>
                    <ion-label
                      style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                      {{ games.crb5c_name }}</ion-label>
                  </div>
                </ion-card>
              </ion-item>
            </ion-row>

            <div class="reportSelectOptions">
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Behaviour:</h3>
                  <v-select v-model="this.mildReport.activities.three.behaviour" :options="behaviourOptions" multiple
                    :reduce="behaviourOptions => behaviourOptions.id">
                    <template v-slot:option="behaviourOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Mood:</h3>
                  <v-select v-model="this.mildReport.activities.three.mood" :options="moodOptions" multiple
                    :reduce="moodOptions => moodOptions.id">
                    <template v-slot:option="moodOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Thoughts:</h3>
                  <v-select v-model="this.mildReport.activities.three.thought" :options="thoughtOptions" multiple
                    :reduce="thoughtOptions => thoughtOptions.id">
                    <template v-slot:option="thoughtOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Participation:</h3>
                  <v-select v-model="this.mildReport.activities.three.participation" :options="participationOptions"
                    multiple :reduce="participationOptions => participationOptions.id">
                    <template v-slot:option="participationOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                  <v-select v-model="this.mildReport.activities.three.alertness" :options="levelofAlertOptions" multiple
                    :reduce="levelofAlertOptions => levelofAlertOptions.id">
                    <template v-slot:option="levelofAlertOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>

                <ion-col>
                  <h3 class="title_left_normal_report">Attention Span:</h3>
                  <v-select v-model="this.mildReport.activities.three.attention" :options="attentionspanOptions"
                    multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                    <template v-slot:option="attentionspanOption">
                      <ion-row>
                        <ion-col>
                          <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                        </ion-col>
                        <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                      </ion-row>
                    </template>
                  </v-select>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true" @input="this.mildReport.activities.three.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-label v-if="!activity3Filled" class="instructions">**Complete activity 3 to proceed to next activity
              </ion-label>
            </div>
            <hr class="lineBreak" />
          </div>

          <div v-if="activity3Filled" class="addOnMild">
            <ion-item lines="none">
              <ion-checkbox slot="start" @update:modelValue="this.activity4Mild.isChecked = $event"
                :modelValue="this.activity4Mild.isChecked" lines="none"></ion-checkbox>
              <ion-label style="font-family:'Montserrat';font-size: 20px;color: rgb(0, 0, 0);font-weight: bold;">
                Activity 4<span v-if="!this.activity4Mild.isChecked">&nbsp; - Optional</span><span
                  style="color: red;font-size: 12px;" v-if="optionalCheckMild">&nbsp; Complete this section to
                  submit</span></ion-label>
            </ion-item>
          </div>

          <div class="subAddOnMild" v-if="this.activity4Mild.isChecked == true">
            <div>
              <ion-radio-group radio-group v-model="this.sessionReportModal.MildActivity4Choice"
                @ionChange="checkActivity4MildRadio">
                <ion-row class="subActivityPickRow">
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="mildActivity4Options?.[0]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Physical</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="mildActivity4Options?.[1]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Psycho-Socio</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="mildActivity4Options?.[2]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Cognitive</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </div>

            <div class="subActivity" v-if="this.sessionReportModal.MildActivity4Choice.length != 0">
              <ion-label class="addon_title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
              <ion-row class="subActivityRow">
                <ion-item v-for="(games, gm) in sessionReportModal[this.sessionReportModal.MildActivity4Choice.text]"
                  :key="`${gm}`" item-left lines="none">
                  <ion-card class="activity_card">
                    <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                    <div class="activityCheckbox">
                      <ion-radio-group radio-group v-model="this.mildReport.activities.four.name"
                        @ionChange="checkActivity1Name">
                        <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                      </ion-radio-group>
                      <ion-label
                        style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                        {{ games.crb5c_name }}</ion-label>
                    </div>
                  </ion-card>
                </ion-item>
              </ion-row>

              <div class="addOnRow">
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Behaviour:</h3>
                    <v-select v-model="this.mildReport.activities.four.behaviour" :options="behaviourOptions" multiple
                      :reduce="behaviourOptions => behaviourOptions.id">
                      <template v-slot:option="behaviourOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Mood:</h3>
                    <v-select v-model="this.mildReport.activities.four.mood" :options="moodOptions" multiple
                      :reduce="moodOptions => moodOptions.id">
                      <template v-slot:option="moodOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Thoughts:</h3>
                    <v-select v-model="this.mildReport.activities.four.thought" :options="thoughtOptions" multiple
                      :reduce="thoughtOptions => thoughtOptions.id">
                      <template v-slot:option="thoughtOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Participation:</h3>
                    <v-select v-model="this.mildReport.activities.four.participation" :options="participationOptions"
                      multiple :reduce="participationOptions => participationOptions.id">
                      <template v-slot:option="participationOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                    <v-select v-model="this.mildReport.activities.four.alertness" :options="levelofAlertOptions"
                      multiple :reduce="levelofAlertOptions => levelofAlertOptions.id">
                      <template v-slot:option="levelofAlertOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Attention Span:</h3>
                    <v-select v-model="this.mildReport.activities.four.attention" :options="attentionspanOptions"
                      multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                      <template v-slot:option="attentionspanOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Additional Remark:</h3>
                    <ion-item class="textAreaActivity" lines="none">
                      <ion-textarea autoGrow="true" @input="this.mildReport.activities.four.remark = $event.target.value"
                        placeholder="Add remarks here"></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </div>
          <div v-if="this.activity4Mild.isChecked == true">
            <hr class="lineBreak" />
          </div>

          <div v-if="activity3Filled" class="reportHeader">
            <ion-label v-if="!endFilled" class="instructions">**Complete End of Session fields to enable submit button
            </ion-label>
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Mood end of session:</h3>
                <v-select v-model="this.mildReport.moodOnEndMild" :options="moodOptions" multiple
                  :reduce="moodOptions => moodOptions.id">
                  <template v-slot:option="moodOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
              <ion-col>
                <h3 class="title_left_normal_report">Behaviour end of session:</h3>
                <v-select v-model="this.mildReport.behaviourOnEndMild" :options="behaviourOptions" multiple
                  :reduce="behaviourOptions => behaviourOptions.id">
                  <template v-slot:option="behaviourOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ behaviourOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
            </ion-row>
          </div>

          <div v-if="endFilled" class="reportBtnSubmit">
            <ion-label v-if="optionalCheckMild" class="instructions">**Complete activity 4 in order to submit
            </ion-label>
            <ion-button class="button_light_submit" :disabled="optionalCheckMild" @click="submitSessionReport"
              style="width:50%; margin: auto;">Submit</ion-button>
          </div>
        </div>
      </ion-content>
    </ion-modal>


    <!-- Session Report moderate -->
    <ion-modal class="my-modal-large"
      :is-open="sessionReportModal.reportType == 1 || sessionReportModal.reportType == 2 || sessionReportModal.reportType == 3"
      backdropDismiss="false">
      <ion-content>
        <ion-button class="modalCloseButton" @click="confirmExitModal">
          <ion-icon :icon="i.close"></ion-icon>
        </ion-button>
        <div class="flex-wrap-override">
          <ion-row>
            <ion-col>
              <h3 class="title_left_medium">{{ this.sessionReportModal.clientDetail.crb5c_client.crb5c_no }}'s Session
                Report</h3>
            </ion-col>
            <ion-col class="col-right">
              <ion-label class="title_left_small">Date: {{ this.sessionReportModal.date.format("DD/MM/YYYY") }}
                &nbsp;&nbsp;
                Time: {{ this.sessionReportModal.date.format("hh:mm A") }}</ion-label>
            </ion-col>
          </ion-row>

          <div class="reportHeader">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Mood on Arrival:</h3>
                <v-select v-model="this.moderateReport.moodOnArrivalModerate" :options="moodOptions" multiple
                  :reduce="moodOptions => moodOptions.id">
                  <template v-slot:option="moodOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
              <ion-col>
                <h3 class="title_left_normal_report">Behaviour on Arrival:</h3>
                <v-select v-model="this.moderateReport.behaviourOnArrivalModerate" :options="behaviourOptions" multiple
                  :reduce="behaviourOptions => behaviourOptions.id">
                  <template v-slot:option="behaviourOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ behaviourOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
            </ion-row>
            <ion-label v-if="!arrivalModFilled" class="instructions">**Complete this section to go to activity selection
            </ion-label>
          </div>
          <hr class="lineBreak" />

          <StickyElement stickMode="element-start" class="stickyBtn">
            <ion-button>Save draft</ion-button>
          </StickyElement>

          <div v-if="arrivalModFilled" class="addOnMild">
            <ion-label v-if="!this.activity1Moderate.isChecked" class="instructions">**Complete atleast 1 activity in
              order to submit report</ion-label>
            <ion-item lines="none">
              <ion-checkbox slot="start" @update:modelValue="this.activity1Moderate.isChecked = $event"
                :modelValue="this.activity1Moderate.isChecked" lines="none"></ion-checkbox>
              <ion-label style="font-family: 'Montserrat' ;font-size: 20px;color: rgb(0, 0, 0);font-weight: bold;">
                Activity 1</ion-label>
            </ion-item>
          </div>

          <div class="subAddOnMild" v-if="this.activity1Moderate.isChecked == true">
            <div>
              <ion-radio-group radio-group v-model="this.sessionReportModal.ModerateActivity1Choice"
                @ionChange="checkActivity1ModerateRadio">
                <ion-row class="subActivityPickRow">
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[0]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Physical</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[1]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Psycho-Socio</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[2]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Cognitive</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[3]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Namaste Care</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col v-if="sessionReportModal.reportType == 3">
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[4]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Individual</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </div>



            <div class="subActivity" v-if="this.sessionReportModal.ModerateActivity1Choice">
              <ion-label class="addon_title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
              <ion-row class="subActivityRow">
                <ion-item v-for="(games, gm) in sessionReportModal[this.sessionReportModal.ModerateActivity1Choice.text]"
                  :key="`${gm}`" item-left lines="none">
                  <ion-card class="activity_card">
                    <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                    <div class="activityCheckbox">
                      <ion-radio-group radio-group v-model="this.moderateReport.activities.one.name"
                        @ionChange="checkActivity1Name">
                        <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                      </ion-radio-group>
                      <ion-label
                        style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                        {{ games.crb5c_name }}</ion-label>
                    </div>
                  </ion-card>
                </ion-item>
              </ion-row>
              <div
                v-if="sessionReportModal.ModerateActivity1Choice.id != 3 && sessionReportModal.ModerateActivity1Choice.id != 4"
                class="addOnRow">
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Behaviour:</h3>
                    <v-select v-model="this.moderateReport.activities.one.behaviour" :options="behaviourOptions"
                      multiple :reduce="behaviourOptions => behaviourOptions.id">
                      <template v-slot:option="behaviourOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Mood:</h3>
                    <v-select v-model="this.moderateReport.activities.one.mood" :options="moodOptions" multiple
                      :reduce="moodOptions => moodOptions.id">
                      <template v-slot:option="moodOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Thoughts:</h3>
                    <v-select v-model="this.moderateReport.activities.one.thought" :options="thoughtOptions" multiple
                      :reduce="thoughtOptions => thoughtOptions.id">
                      <template v-slot:option="thoughtOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Participation:</h3>
                    <v-select v-model="this.moderateReport.activities.one.participation" :options="participationOptions"
                      multiple :reduce="participationOptions => participationOptions.id">
                      <template v-slot:option="participationOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                    <v-select v-model="this.moderateReport.activities.one.alertness" :options="levelofAlertOptions"
                      multiple :reduce="levelofAlertOptions => levelofAlertOptions.id">
                      <template v-slot:option="levelofAlertOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Attention Span:</h3>
                    <v-select v-model="this.moderateReport.activities.one.attention" :options="attentionspanOptions"
                      multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                      <template v-slot:option="attentionspanOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Additional Remark:</h3>
                    <ion-item class="textAreaActivity" lines="none">
                      <ion-textarea autoGrow="true"
                        @input="this.moderateReport.activities.one.remark = $event.target.value"
                        placeholder="Add remarks here"></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>

              <div
                v-if="sessionReportModal.ModerateActivity1Choice.id == 3 || sessionReportModal.ModerateActivity1Choice.id == 4"
                class="addOnRow">
                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">1. Facial Expression:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.facialBegin" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.facialEnd" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>


                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">2. Eye Contact:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.eyeContactBegin"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.eyeContactEnd"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">3. Interest in Communicating:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.interestBegin" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.interestEnd" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">4. Body Posture:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.postureBegin" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.postureEnd" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">5. Mood:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.moodBegin" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.moodEnd" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">6. Behaviour:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.behaviourBegin"
                          :options="behaviourOptions" multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.one.behaviourEnd" :options="behaviourOptions"
                          multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
                <ion-row>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true" @input="this.moderateReport.activities.one.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-row>
              </div>
              <ion-label v-if="!activity1ModFilled" class="instructions">**Complete this section to proceed to the next
                one</ion-label>
            </div>
          </div>
          <div v-if="this.activity1Moderate.isChecked == true">
            <hr class="lineBreak" />
          </div>


          <div v-if="activity1ModFilled" class="addOnMild">
            <ion-item lines="none">
              <ion-checkbox slot="start" @update:modelValue="this.activity2Moderate.isChecked = $event"
                :modelValue="this.activity2Moderate.isChecked" lines="none"></ion-checkbox>
              <ion-label style="font-family:'Montserrat' ;font-size: 20px;color: rgb(0, 0, 0);font-weight: bold;">
                Activity 2<span v-if="!this.activity2Moderate.isChecked">&nbsp; - Optional</span><span
                  style="color: red;font-size: 12px;"
                  v-if="this.activity2Moderate.isChecked && !this.activity2ModFilled">&nbsp; Complete this section to
                  submit</span></ion-label>
            </ion-item>
          </div>

          <div class="subAddOnMild" v-if="this.activity2Moderate.isChecked == true">
            <div>
              <ion-radio-group radio-group v-model="this.sessionReportModal.ModerateActivity2Choice"
                @ionChange="checkActivity2ModerateRadio()">
                <ion-row class="subActivityPickRow">
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[0]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Physical</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOption?.[1]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Psycho-Socio</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[2]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Cognitive</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[3]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Namaste Care</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col v-if="sessionReportModal.reportType == 3">
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[4]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Individual</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </div>

            <div class="subActivity" v-if="this.sessionReportModal.ModerateActivity2Choice">
              <ion-label class="addon_title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
              <ion-row class="subActivityRow">
                <ion-item v-for="(games, gm) in sessionReportModal[this.sessionReportModal.ModerateActivity2Choice.text]"
                  :key="`${gm}`" item-left lines="none">
                  <ion-card class="activity_card">
                    <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                    <div class="activityCheckbox">
                      <ion-radio-group radio-group v-model="this.moderateReport.activities.two.name"
                        @ionChange="checkActivity1Name">
                        <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                      </ion-radio-group>
                      <ion-label
                        style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                        {{ games.crb5c_name }}</ion-label>
                    </div>
                  </ion-card>
                </ion-item>
              </ion-row>

              <div
                v-if="sessionReportModal.ModerateActivity2Choice.id != 3 && sessionReportModal.ModerateActivity2Choice.id != 4"
                class="addOnRow">
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Behaviour:</h3>
                    <v-select v-model="this.moderateReport.activities.two.behaviour" :options="behaviourOptions"
                      multiple :reduce="behaviourOptions => behaviourOptions.id">
                      <template v-slot:option="behaviourOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Mood:</h3>
                    <v-select v-model="this.moderateReport.activities.two.mood" :options="moodOptions" multiple
                      :reduce="moodOptions => moodOptions.id">
                      <template v-slot:option="moodOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Thoughts:</h3>
                    <v-select v-model="this.moderateReport.activities.two.thought" :options="thoughtOptions" multiple
                      :reduce="thoughtOptions => thoughtOptions.id">
                      <template v-slot:option="thoughtOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Participation:</h3>
                    <v-select v-model="this.moderateReport.activities.two.participation" :options="participationOptions"
                      multiple :reduce="participationOptions => participationOptions.id">
                      <template v-slot:option="participationOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                    <v-select v-model="this.moderateReport.activities.two.alertness" :options="levelofAlertOptions"
                      multiple :reduce="levelofAlertOptions => levelofAlertOptions.id">
                      <template v-slot:option="levelofAlertOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Attention Span:</h3>
                    <v-select v-model="this.moderateReport.activities.two.attention" :options="attentionspanOptions"
                      multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                      <template v-slot:option="attentionspanOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Additional Remark:</h3>
                    <ion-item class="textAreaActivity" lines="none">
                      <ion-textarea autoGrow="true"
                        @input="this.moderateReport.activities.two.remark = $event.target.value"
                        placeholder="Add remarks here"></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>

              <div
                v-if="sessionReportModal.ModerateActivity2Choice.id == 3 || sessionReportModal.ModerateActivity2Choice.id == 4"
                class="addOnRow">
                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">1. Facial Expression:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.facialBegin" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.facialEnd" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">2. Eye Contact:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.eyeContactBegin"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.eyeContactEnd"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">3. Interest in Communicating:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.interestBegin" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.interestEnd" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">4. Body Posture:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.postureBegin" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.postureEnd" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">5. Mood:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.moodBegin" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.moodEnd" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">6. Behaviour:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.behaviourBegin"
                          :options="behaviourOptions" multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.two.behaviourEnd" :options="behaviourOptions"
                          multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
                <ion-row>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true" @input="this.moderateReport.activities.two.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-row>
              </div>

            </div>
          </div>
          <div v-if="this.activity2Moderate.isChecked == true">
            <hr class="lineBreak" />
          </div>

          <div v-if="activity2ModFilled" class="addOnMild">
            <ion-item lines="none">
              <ion-checkbox slot="start" @update:modelValue="this.activity3Moderate.isChecked = $event"
                :modelValue="this.activity3Moderate.isChecked" lines="none"></ion-checkbox>
              <ion-label style="font-family: 'Montserrat' ;font-size: 20px;color: rgb(0, 0, 0);font-weight: bold;">
                Activity 3<span v-if="!this.activity3Moderate.isChecked">&nbsp; - Optional</span><span
                  style="color: red;font-size: 12px;"
                  v-if="this.activity3Moderate.isChecked && !this.activity3ModFilled">&nbsp; Complete this section to
                  submit</span></ion-label>
            </ion-item>
          </div>

          <div class="subAddOnMild" v-if="this.activity3Moderate.isChecked == true">
            <div>
              <ion-radio-group radio-group v-model="this.sessionReportModal.ModerateActivity3Choice"
                @ionChange="checkActivity3ModerateRadio()">
                <ion-row class="subActivityPickRow">
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[0]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Physical</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[1]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Psycho-Socio</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[2]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Cognitive</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[3]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Namaste Care</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col v-if="sessionReportModal.reportType == 3">
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[4]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Individual</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </div>

            <div class="subActivity" v-if="this.sessionReportModal.ModerateActivity3Choice">
              <ion-label class="addon_title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
              <ion-row class="subActivityRow">
                <ion-item v-for="(games, gm) in sessionReportModal[this.sessionReportModal.ModerateActivity3Choice.text]"
                  :key="`${gm}`" item-left lines="none">
                  <ion-card class="activity_card">
                    <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                    <div class="activityCheckbox">
                      <ion-radio-group radio-group v-model="this.moderateReport.activities.three.name"
                        @ionChange="checkActivity1Name">
                        <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                      </ion-radio-group>
                      <ion-label
                        style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                        {{ games.crb5c_name }}</ion-label>
                    </div>
                  </ion-card>
                </ion-item>
              </ion-row>
              <div
                v-if="sessionReportModal.ModerateActivity3Choice.id != 3 && sessionReportModal.ModerateActivity3Choice.id != 4"
                class="addOnRow">
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Behaviour:</h3>
                    <v-select v-model="this.moderateReport.activities.three.behaviour" :options="behaviourOptions"
                      multiple :reduce="behaviourOptions => behaviourOptions.id">
                      <template v-slot:option="behaviourOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Mood:</h3>
                    <v-select v-model="this.moderateReport.activities.three.mood" :options="moodOptions" multiple
                      :reduce="moodOptions => moodOptions.id">
                      <template v-slot:option="moodOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Thoughts:</h3>
                    <v-select v-model="this.moderateReport.activities.three.thought" :options="thoughtOptions" multiple
                      :reduce="thoughtOptions => thoughtOptions.id">
                      <template v-slot:option="thoughtOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Participation:</h3>
                    <v-select v-model="this.moderateReport.activities.three.participation"
                      :options="participationOptions" multiple
                      :reduce="participationOptions => participationOptions.id">
                      <template v-slot:option="participationOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                    <v-select v-model="this.moderateReport.activities.three.alertness" :options="levelofAlertOptions"
                      multiple :reduce="levelofAlertOptions => levelofAlertOptions.id">
                      <template v-slot:option="levelofAlertOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Attention Span:</h3>
                    <v-select v-model="this.moderateReport.activities.three.attention" :options="attentionspanOptions"
                      multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                      <template v-slot:option="attentionspanOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Additional Remark:</h3>
                    <ion-item class="textAreaActivity" lines="none">
                      <ion-textarea autoGrow="true"
                        @input="this.moderateReport.activities.three.remark = $event.target.value"
                        placeholder="Add remarks here"></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>

              <div
                v-if="sessionReportModal.ModerateActivity3Choice.id == 3 || sessionReportModal.ModerateActivity3Choice.id == 4"
                class="addOnRow">
                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">1. Facial Expression:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.facialBegin" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.facialEnd" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>


                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">2. Eye Contact:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.eyeContactBegin"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.eyeContactEnd"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">3. Interest in Communicating:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.interestBegin"
                          :options="interestOptions" multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.interestEnd" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">4. Body Posture:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.postureBegin" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.postureEnd" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">5. Mood:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.moodBegin" :options="moodOptions"
                          multiple :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.moodEnd" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">6. Behaviour:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.behaviourBegin"
                          :options="behaviourOptions" multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.three.behaviourEnd"
                          :options="behaviourOptions" multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
                <ion-row>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true"
                      @input="this.moderateReport.activities.three.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-row>
              </div>
            </div>
          </div>
          <div v-if="this.activity3Moderate.isChecked == true">
            <hr class="lineBreak" />
          </div>

          <div v-if="activity3ModFilled" class="addOnMild">
            <ion-item lines="none">
              <ion-checkbox slot="start" @update:modelValue="this.activity4Moderate.isChecked = $event"
                :modelValue="this.activity4Moderate.isChecked" lines="none"></ion-checkbox>
              <ion-label style="font-family:'Montserrat' ;font-size: 20px;color: rgb(0, 0, 0);font-weight: bold;">
                Activity 4<span v-if="!this.activity4Moderate.isChecked">&nbsp; - Optional</span><span
                  style="color: red; font-size: 12px;"
                  v-if="this.activity4Moderate.isChecked && !this.activity4ModFilled">&nbsp; Complete this section to
                  submit</span></ion-label>
            </ion-item>
          </div>

          <div class="subAddOnMild" v-if="this.activity4Moderate.isChecked == true">
            <div>
              <ion-radio-group radio-group v-model="this.sessionReportModal.ModerateActivity4Choice"
                @ionChange="checkActivity4ModerateRadio">
                <ion-row class="subActivityPickRow">
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[0]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Physical</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[1]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Psycho-Socio</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[2]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Cognitive</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[3]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Namaste Care</ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col v-if="sessionReportModal.reportType == 3">
                    <ion-item lines="none">
                      <ion-radio mode="md" :value="moderateActivityOptions?.[4]"></ion-radio>
                      <ion-label class="subActivityPickLabel">Individual</ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </div>



            <div class="subActivity" v-if="this.sessionReportModal.ModerateActivity4Choice">
              <ion-label class="addon_title_left_normal">Participated in the following activities (select accordingly):
              </ion-label>
              <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
              <ion-row class="subActivityRow">
                <ion-item v-for="(games, gm) in sessionReportModal[this.sessionReportModal.ModerateActivity4Choice.text]"
                  :key="`${gm}`" item-left lines="none">
                  <ion-card class="activity_card">
                    <ion-img :src="'data:image/jpeg;base64,' + games.crb5c_activityimage" class="activityImg"></ion-img>
                    <div class="activityCheckbox">
                      <ion-radio-group radio-group v-model="this.moderateReport.activities.four.name"
                        @ionChange="checkActivity1Name">
                        <ion-radio style="margin-left: 20px;" mode="md" :value="games.crb5c_name"></ion-radio>
                      </ion-radio-group>
                      <ion-label
                        style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                        {{ games.crb5c_name }}</ion-label>
                    </div>
                  </ion-card>
                </ion-item>
              </ion-row>
              <div
                v-if="sessionReportModal.ModerateActivity4Choice.id != 3 && sessionReportModal.ModerateActivity4Choice.id != 4"
                class="addOnRow">
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Behaviour:</h3>
                    <v-select v-model="this.moderateReport.activities.four.behaviour" :options="behaviourOptions"
                      multiple :reduce="behaviourOptions => behaviourOptions.id">
                      <template v-slot:option="behaviourOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ behaviourOption.label }} </ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Mood:</h3>
                    <v-select v-model="this.moderateReport.activities.four.mood" :options="moodOptions" multiple
                      :reduce="moodOptions => moodOptions.id">
                      <template v-slot:option="moodOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Thoughts:</h3>
                    <v-select v-model="this.moderateReport.activities.four.thought" :options="thoughtOptions" multiple
                      :reduce="thoughtOptions => thoughtOptions.id">
                      <template v-slot:option="thoughtOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="thoughtOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ thoughtOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Participation:</h3>
                    <v-select v-model="this.moderateReport.activities.four.participation"
                      :options="participationOptions" multiple
                      :reduce="participationOptions => participationOptions.id">
                      <template v-slot:option="participationOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="participationOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ participationOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Level Of Alertness:</h3>
                    <v-select v-model="this.moderateReport.activities.four.alertness" :options="levelofAlertOptions"
                      multiple :reduce="levelofAlertOptions => levelofAlertOptions.id">
                      <template v-slot:option="levelofAlertOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="levelofAlertOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ levelofAlertOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>

                  <ion-col>
                    <h3 class="title_left_normal_report">Attention Span:</h3>
                    <v-select v-model="this.moderateReport.activities.four.attention" :options="attentionspanOptions"
                      multiple :reduce="attentionspanOptions => attentionspanOptions.id">
                      <template v-slot:option="attentionspanOption">
                        <ion-row>
                          <ion-col>
                            <ion-img :src="attentionspanOption.img" class="dropDownIcon"></ion-img>
                          </ion-col>
                          <ion-col class="colSelect">{{ attentionspanOption.label }}</ion-col>
                        </ion-row>
                      </template>
                    </v-select>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <h3 class="title_left_normal_report">Additional Remark:</h3>
                    <ion-item class="textAreaActivity" lines="none">
                      <ion-textarea autoGrow="true"
                        @input="this.moderateReport.activities.four.remark = $event.target.value"
                        placeholder="Add remarks here"></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>

              <div
                v-if="sessionReportModal.ModerateActivity4Choice.id == 3 || sessionReportModal.ModerateActivity4Choice.id == 4"
                class="addOnRow">
                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">1. Facial Expression:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.facialBegin" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.facialEnd" :options="facialOptions"
                          multiple :reduce="facialOptions => facialOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>


                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">2. Eye Contact:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.eyeContactBegin"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.eyeContactEnd"
                          :options="eyeContactOptions" multiple :reduce="eyeContactOptions => eyeContactOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">3. Interest in Communicating:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.interestBegin" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.interestEnd" :options="interestOptions"
                          multiple :reduce="interestOptions => interestOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">4. Body Posture:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.postureBegin" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.postureEnd" :options="postureOptions"
                          multiple :reduce="postureOptions => postureOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">5. Mood:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.moodBegin" :options="moodOptions"
                          multiple :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.moodEnd" :options="moodOptions" multiple
                          :reduce="moodOptions => moodOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div>
                  <ion-row>
                    <ion-col>
                      <h3 class="text-left-medium-individual">6. Behaviour:</h3>
                    </ion-col>
                  </ion-row>
                  <div>
                    <ion-row>
                      <ion-col>
                        <h3 class="title_left_normal_report">Beginning of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.behaviourBegin"
                          :options="behaviourOptions" multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>

                      <ion-col>
                        <h3 class="title_left_normal_report">End of Session:</h3>
                        <v-select v-model="this.moderateReport.activities.four.behaviourEnd" :options="behaviourOptions"
                          multiple :reduce="behaviourOptions => behaviourOptions.id">
                        </v-select>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
                <ion-row>
                  <h3 class="title_left_normal_report">Additional Remark:</h3>
                  <ion-item class="textAreaActivity" lines="none">
                    <ion-textarea autoGrow="true"
                      @input="this.moderateReport.activities.four.remark = $event.target.value"
                      placeholder="Add remarks here"></ion-textarea>
                  </ion-item>
                </ion-row>

              </div>

            </div>
          </div>
          <div v-if="this.activity4Moderate.isChecked == true">
            <hr class="lineBreak" />
          </div>

          <div v-if="activity1ModFilled" class="reportHeader">
            <ion-label v-if="!endModFilled" class="instructions">**Complete End of Session fields to enable submit
              button</ion-label>
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Mood end of session:</h3>
                <v-select v-model="this.moderateReport.moodOnEndModerate" :options="moodOptions" multiple
                  :reduce="moodOptions => moodOptions.id">
                  <template v-slot:option="moodOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="moodOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ moodOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
              <ion-col>
                <h3 class="title_left_normal_report">Behaviour end of session:</h3>
                <v-select v-model="this.moderateReport.behaviourOnEndModerate" :options="behaviourOptions" multiple
                  :reduce="behaviourOptions => behaviourOptions.id">
                  <template v-slot:option="behaviourOption">
                    <ion-row>
                      <ion-col>
                        <ion-img :src="behaviourOption.img" class="dropDownIcon"></ion-img>
                      </ion-col>
                      <ion-col class="colSelect">{{ behaviourOption.label }}</ion-col>
                    </ion-row>
                  </template>
                </v-select>
              </ion-col>
            </ion-row>
          </div>

          <div v-if="endModFilled" class="reportBtnSubmit">
            <ion-row>
              <ion-label v-if="optionalCheckModerate" style="color:red" class="instructions">**There are fields left to
                be filled in order to submit the report</ion-label>
            </ion-row>
            <ion-row>
              <ion-button class="button_light_submit" :disabled="optionalCheckModerate" @click="submitSessionReport"
                style="width:50%; margin: auto;">Submit</ion-button>
            </ion-row>

          </div>
        </div>
      </ion-content>
    </ion-modal>


    <ion-modal :is-open="sessionReportModal.uploadPicModal" class="picImageModal">
      <div style="overflow-y: scroll; height:400px;" class="chooseImageBOx">
        <file-pond label-idle="Click here to choose images" v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png" v-bind:files="myFiles" v-model="myFiles2"
          v-on:init="handleFilePondInit" />
      </div>
      <div class="reportBtnSubmit">
        <ion-button class="button_light_submit" @click="uploadActivity()" style="width:30%; margin: auto;">Upload
        </ion-button>
      </div>
    </ion-modal>

    <!-- Session Report Individual
    <ion-modal class="my-modal-large" :is-open="sessionReportModal.showIndividual">
      <ion-content>
        <ion-row>
          <ion-col>
            <h3 class="title_left_medium">{{this.sessionReportModal.clientDetail.crb5c_client.crb5c_no}}'s Session
              Report (Namaste Care)</h3>
          </ion-col>
          <ion-col class="col-right">
            <ion-label class="title_left_small">Date: {{this.sessionReportModal.date.format("DD/MM/YYYY")}} &nbsp;&nbsp;
              Time: {{this.sessionReportModal.date.format("hh:mm A")}}</ion-label>
          </ion-col>
        </ion-row>
        <div>
          <div style="margin-bottom: 30px;margin-top: 40px;">
            <ion-label class="title_left_normal">Participated in the following activities (select accordingly):
            </ion-label>
            <ion-button class="add_button_small" @click="addNewActivity()">Add new activity</ion-button>
          </div>
          <ion-row class="center-row">
            <ion-item v-for="(games,gm) in sessionReportModal.indi" :key="`${gm}`" item-left lines="none">
              <ion-card class="activity_card">
                <ion-img :src="games.img" class="activityImg"></ion-img>
                <div class="activityCheckbox">
                  <ion-checkbox style="margin-left: 20px;"></ion-checkbox>
                  <ion-label
                    style="font-size: 16px;color: #fff;text-align: center;margin-top: 12px; margin-left: 10px; font-weight: normal;">
                    {{games.name}}</ion-label>
                </div>
              </ion-card>
            </ion-item>
          </ion-row>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">1. Facial Expression:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.facialBegin" :options="facialOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.facialEnd" :options="facialOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">2. Eye Contact:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row class="center-row">
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.eyeContactBegin" :options="eyeContactOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.eyeContactEnd" :options="eyeContactOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">3. Interest in Communicating:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.interestBegin" :options="interestOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.interestEnd" :options="interestOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">4. Body Posture:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.postureBegin" :options="postureOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.postureEnd" :options="postureOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">5. Mood:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.moodBegin" :options="moodOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.moodEnd" :options="moodOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div>
          <ion-row>
            <ion-col>
              <h3 class="text-left-medium-individual">6. Behaviour:</h3>
            </ion-col>
          </ion-row>
          <div class="reportSelectOptions">
            <ion-row>
              <ion-col>
                <h3 class="title_left_normal_report">Beginning of Session:</h3>
                <v-select v-model="this.sessionReportModal.behaviourBegin" :options="behaviourOptions" multiple>
                </v-select>
              </ion-col>

              <ion-col>
                <h3 class="title_left_normal_report">End of Session:</h3>
                <v-select v-model="this.sessionReportModal.behaviourEnd" :options="behaviourOptions" multiple>
                </v-select>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div class="reportBtnSubmit">
          <ion-button class="button_light_submit" @click="addPicAlert()" style="width:30%; margin: auto;">Submit
          </ion-button>
        </div>
      </ion-content>
    </ion-modal> -->

    <ion-modal :swipe-to-close="false" :is-open="adhoclistModal.show">
      <ion-content v-if="adhoclistModal.adhocsessions.length > 0">
        <h3 class="text-center" style="margin-top:40px;">Pick an adhoc session to enroll:</h3>
        <div class="centerList" style="margin-left:60px;margin-right:70px;">
          <hr />
          <hr />
          <ion-item v-for="(adhocsess, index) in adhoclistModal.adhocsessions" :key="index"
            v-on:click="pickadhoc(index)">
            <ion-label><strong>{{ adhocsess.crb5c_sessionname }}</strong></ion-label>
          </ion-item>
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <div class="center" style="margin-top:150px;">
            <ion-button class="button_light" style="width:30%;margin-top:100px;margin: auto;"
              @click="this.clearmodal()">Close</ion-button>
          </div>
        </div>
      </ion-content>
      <ion-content>
        <h3 class="text-center" style="margin-top:150px;">No adhoc session is available</h3>
        <div class="center">
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <hr />
          <ion-button class="button_light" style="width:30%;margin:auto;" @click="this.clearmodal()">Close</ion-button>
        </div>
      </ion-content>
    </ion-modal>

    <ion-content :fullscreen="true">
      <ion-toolbar class="MainToolbar">
        <ion-title>Check-{{ checkOutMode ? 'Out' : 'In' }}</ion-title>
      </ion-toolbar>

      <ion-header>
        <ion-toolbar class="NavToolbar">
          <div class="center">
            <ion-button class="ToolbarBtn" v-if="ready" @click="showManualCheckInModal">Manual Check-{{ checkOutMode ?
                'Out' : 'In'
            }}</ion-button>
            <ion-button class="ToolbarBtn" v-if="ready" @click="linkOut(links.attendanceDashboardLink)">Attendance
              Dashboard</ion-button>
            <ion-button class="ToolbarBtn" v-if="ready" @click="openActivePage">Sessions</ion-button>

            <ion-button class="ToolbarBtn" v-if="ready" @click="activePage = 'configurations'">
              <ion-icon :icon="i.settingsOutline"></ion-icon>
            </ion-button>
          </div>
        </ion-toolbar>
      </ion-header>



      <div class="divBack">
        <div v-if="promptLogin">
          <ion-button @click="login">Login</ion-button>
        </div>
        <ion-grid class="mainHolder" v-if="activePage === 'scanning'">
          <div>
            <div class="divHolder">
              <div class="info">
                <ion-card class="centerCard ionCardBorderPurple">
                  <ion-card-header>
                  </ion-card-header>
                  <ion-card-content class="centerCard">
                    <ion-icon :icon="i.scan" class="iconstyle"></ion-icon>
                    <ion-label class="scanLabel"><strong>SCAN TO
                        {{ checkOutMode ? 'CHECK-OUT' : 'CHECK-IN' }}</strong></ion-label>
                  </ion-card-content>
                </ion-card>
              </div>
              <div class="toggle_div">
                <ion-item lines="none" style="color:rgb(80,39,107);">
                  <ion-label v-if="checkOutMode" class="checkLabel">Check-out Mode</ion-label>
                  <ion-label v-else class="checkLabel">Check-in Mode</ion-label>
                  <ion-toggle color="warning" v-model="checkOutMode"></ion-toggle>
                </ion-item>
              </div>
            </div>
          </div>
        </ion-grid>
        <div v-else-if="activePage === 'sessionsToday'">
          <div class="d-flex ion-justify-content-end ion-padding" style="margin-top:10px">
            <ion-button class="ToolbarBtnSmall" @click="filterSession" v-if="sessionsPage.fe == 'main'">
              <ion-icon :icon="i.filter" class="button_icon"></ion-icon>
              Filter Session
            </ion-button>
            <ion-button class="ToolbarBtnSmall" @click="backSession" v-if="sessionsPage.fe !== 'main'">
              <ion-icon :icon="i.arrowBack" class="button_icon"></ion-icon>
              Back to {{ previousSessionPage }}</ion-button>
            <ion-button class="ToolbarBtnSmall" @click="activePage = 'scanning'">
              <ion-icon :icon="i.home" class="button_icon"></ion-icon>
              Back to
              {{ checkOutMode ? 'check-out' : 'check-in' }} page</ion-button>
            <template v-if="false">
              <ion-checkbox class="my-temp-chckbox" slot="end" v-model="haveToFillInTemp"></ion-checkbox>
              <ion-label class="checkbox-label">Enable Temperature Check</ion-label>
            </template>
          </div>
          <div class="center" v-if="fetchingAllSessions">
            Getting data...
          </div>
          <div v-else>
            <div v-if="sessionsPage.fe == 'main'">
              <h1 v-if="this.filterDate && this.filterDate != this.todayFilter" class="ion-margin text-center"
                style="font-weight:bold;margin-top: 45px;">Sessions
                on {{ this.filterDate }}</h1>
              <h1 v-else class="ion-margin text-center" style="font-weight:bold;margin-top: 45px;">Sessions Today</h1>
              <div v-if="this.isFilter" class="filterDiv">
                <ion-row class=" ion-align-items-end ion-justify-content-end">
                  <ion-button fill="clear" class="filter_close_btn" @click=closefilterdiv>
                    <ion-icon :icon="i.close"></ion-icon>
                  </ion-button>
                </ion-row>
                <ion-datetime presentation="date" class="session_filter" v-model="this.filterDate"></ion-datetime>
                <ion-button class="filterButton" @click="fetchAllSessionsToday(this.filterDate)">Filter</ion-button>
              </div>

              <ion-grid v-if="allSessionsToday.length">
                <ion-row style="flex-wrap: wrap;" class="ion-align-items-center ion-justify-content-center">
                  <ion-col size="2" size-md="auto" v-for="(s, i) in allSessionsToday" :key="`session_${i}`"
                    class="clickable" @click="setSessionAndProceedToRooms(s.crb5c_fow_sessionid, s.crb5c_sessionname)">
                    <ion-card class="ionCardBorder">
                      <ion-card-content class="centerCardSession card-text-title">
                        <ion-img style="width: 150px; height: 150px; padding:10px" :src="defaultSessionImg"></ion-img>
                        <h2>{{ s.crb5c_sessionname }}</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div v-else-if="sessionsPage.fe == 'rooms'">
              <h1 class="text-center ion-margin" style="font-weight:bold; margin-top: 45px;">Rooms in
                {{ sessionsPage.sessionName }}</h1>
              <ion-grid>
                <ion-row style="flex-wrap: wrap;" class="ion-align-items-center ion-justify-content-center">
                  <ion-col size="2" size-md="auto" v-for="(r, i) in allRooms" :key="`room_${i}`" class="clickable"
                    @click="fetchClientsInSession(r.id)">
                    <ion-card class="cardHolder ionCardBorder">
                      <ion-card-content class="centerCardSession card-text-title">
                        <ion-img style="width: 150px; height: 150px; padding:20px" :src="defaultRoomImg"></ion-img>
                        <h2>{{ r.name }}</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div v-else-if="sessionsPage.fe == 'attendees'">
              <ion-grid v-if="allAttendees.length">
                <h1 class="text-center ion-margin" style="font-weight:bold;margin-top: 45px;">Session:
                  {{ sessionsPage.sessionName }}, Room:
                  {{ sessionsPage.roomName }}
                  <ion-button style="margin-bottom:20px; margin-left: 20px;" class="ToolbarBtn" @click="refreshpage">
                    <ion-icon :icon="i.refresh"></ion-icon>
                  </ion-button>
                </h1>
                <hr class="divider" />

                <h3 class="ion-padding" style="font-weight:bold;margin-left: 30px;">Not checked-out:</h3>
                <!-- <div class="d-flex ion-justify-content-end ion-padding" v-if="allAttendees.filter(a => !a.crb5c_checkout_time && a.crb5c_checkin_time).length">
                    <ion-button class="ToolbarBtn" @click="bulkCheckout">Check out everyone here</ion-button>
                  </div> -->
                <ion-row class="ion-align-items-center ion-justify-content-center">
                  <div v-for="(a, i) in allAttendees" :key="`attendee_${i}`">
                    <ion-card v-if="!a.crb5c_checkout_time && a.crb5c_checkin_time" class="clickable ionCardBorder"
                      @click="showAttendeeActionSheet(a)">
                      <ion-card-content class="centerCardProfile  card-text-title">
                        <ion-img
                          :style="`width: 200px;${imageLink(a.crb5c_client) == defaultUserImg ? 'height: 200px;' : ''}`"
                          :src="imageLink(a.crb5c_client)"></ion-img>
                        <h2 style="margin-top:25px;">{{ a.crb5c_client.crb5c_no }}</h2>
                      </ion-card-content>
                    </ion-card>
                  </div>
                </ion-row>
                <hr class="divider" />
                <h3 class="ion-padding" style="font-weight:bold;margin-left: 30px;">Checked-out:</h3>
                <ion-row class="ion-align-items-center ion-justify-content-center">
                  <div v-for="(a) in allAttendees" :key="`attendee_${a._modifiedby_value}`">
                    <ion-card v-if="a.crb5c_checkout_time" class="clickable ionCardBorder"
                      @click="showAttendeeActionSheet(a)">
                      <ion-card-content class="centerCardProfile card-text-title">
                        <h2 style="margin-bottom:25px;">Report status: <span
                            :class="(a.crb5c_reportstatus == 'Not Done') ? 'alert-danger-text' : 'alert-normal-text'">{{ a.crb5c_reportstatus }}</span>
                        </h2>
                        <ion-img
                          :style="`width: 200px;${imageLink(a.crb5c_client) == defaultUserImg ? 'height: 200px;' : ''}`"
                          :src="imageLink(a.crb5c_client)"></ion-img>
                        <h2 style="margin-top:25px;">{{ a.crb5c_client.crb5c_no }}</h2>
                      </ion-card-content>
                    </ion-card>
                  </div>
                </ion-row>
              </ion-grid>
              <ion-grid v-else>
                <h1 class="text-center ion-margin" style="font-weight:bold;margin-top: 45px;">Session:
                  {{ sessionsPage.sessionName }}, Room:
                  {{ sessionsPage.roomName }}</h1>
                <div class="text-center ion-margin" style="margin-top: 10%;">
                  <div>There currently isn't any attendee in this session and room</div>
                </div>
              </ion-grid>
            </div>
            <div class="center" v-else>
              <div>There isn't any session today</div>
            </div>
          </div>
        </div>
        <div v-else-if="activePage === 'configurations'">
          <div class="d-flex ion-justify-content-end ion-padding">
            <ion-button class="ToolbarBtn" @click="activePage = 'scanning'">Back to
              {{ checkOutMode ? 'check-out' : 'check-in' }} page
            </ion-button>
          </div>
          <div>
            <ion-grid id="config-table">
              <ion-row>
                <ion-col class="header" size="4">Configuration Name</ion-col>
                <ion-col class="header" size="4">Configuration Description</ion-col>
                <ion-col class="header" size="4">Value</ion-col>
              </ion-row>
              <ion-row v-for="(c, i) in config" :key="`config_${i}`">
                <ion-col class="body" size="4">{{ c.crb5c_configurationname }}</ion-col>
                <ion-col class="body" size="4">{{ c.crb5c_configurationdescription }}</ion-col>
                <template v-if="ConfigType[c.crb5c_valuetype] === 'Yes/No'">
                  <ion-col class="body" size="4">
                    <v-select v-model="c.crb5c_value" :options="configYesNoOptions" :disabled="updatingConfig"
                      :reduce="configYesNoOptions => configYesNoOptions.value">
                    </v-select>
                  </ion-col>
                </template>
              </ion-row>
            </ion-grid>
          </div>

        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonList,
  IonModal,
  alertController,
  IonAvatar,
  IonLabel,
  IonInput,
  IonHeader,
  IonPage,
  IonToggle,
  IonTitle,
  IonToolbar,
  IonItem,
  IonIcon,
  IonCard,
  // IonSelect,
  // IonSelectOption,
  IonCardContent,
  IonGrid,
  IonButton,
  IonCheckbox,
  IonImg,
  IonCardHeader,
  actionSheetController,
  IonTextarea,
  IonDatetime,



} from "@ionic/vue";
// import VueScrollSnap from "vue-scroll-snap";
import * as allIcons from "ionicons/icons";
import { defineComponent } from "vue";
import dayjs from "dayjs";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import StickyElement from 'vue-sticky-element';

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import isToday from "dayjs/plugin/isToday";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const ConfigType = {
  1: "Yes/No",
  2: "Single text",
  3: "Multiple texts",
  4: "Multiple numbers"
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
const defaultTimezone = "Asia/Singapore";
dayjs.tz.setDefault(defaultTimezone);

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

import axios from "axios";
import * as msal from "@azure/msal-browser";
import { debounce } from 'debounce';



export default defineComponent({
  name: "HomePage",
  computed: {
    arrivalFilled() {
      if (this.mildReport.moodOnArrivalMild.length && this.mildReport.behaviourOnArrivalMild.length) {
        return true;
      }
      return false;
    },
    arrivalModFilled() {
      if (this.moderateReport.behaviourOnArrivalModerate.length && this.moderateReport.moodOnArrivalModerate.length) {
        return true;
      }
      return false;
    },
    endFilled() {
      if (this.mildReport.moodOnEndMild.length && this.mildReport.behaviourOnEndMild.length) {
        return true;
      }
      return false;
    },
    endModFilled() {
      if (this.moderateReport.behaviourOnEndModerate.length && this.moderateReport.moodOnEndModerate.length) {
        return true;
      }
      return false;
    },
    activity1Filled() {
      if (this.mildReport.activities.one.name != '' && this.mildReport.activities.one.behaviour.length && this.mildReport.activities.one.mood.length && this.mildReport.activities.one.thought.length && this.mildReport.activities.one.participation.length && this.mildReport.activities.one.alertness.length && this.mildReport.activities.one.attention.length) {
        return true;
      }
      return false;
    },
    activity2Filled() {
      if (this.mildReport.activities.two.name != '' && this.mildReport.activities.two.behaviour.length && this.mildReport.activities.two.mood.length && this.mildReport.activities.two.thought.length && this.mildReport.activities.two.participation.length && this.mildReport.activities.two.alertness.length && this.mildReport.activities.two.attention.length) {
        return true;
      }
      return false;
    },
    activity3Filled() {
      if (this.mildReport.activities.three.name != '' && this.mildReport.activities.three.behaviour.length && this.mildReport.activities.three.mood.length && this.mildReport.activities.three.thought.length && this.mildReport.activities.three.participation.length && this.mildReport.activities.three.alertness.length && this.mildReport.activities.three.attention.length) {
        return true;
      }
      return false;
    },
    activity1ModFilled() {
      if ((this.moderateReport.activities.one.name != '' && this.moderateReport.activities.one.behaviour.length && this.moderateReport.activities.one.mood.length && this.moderateReport.activities.one.participation.length && this.moderateReport.activities.one.thought.length && this.moderateReport.activities.one.alertness.length && this.moderateReport.activities.one.attention.length) || (this.moderateReport.activities.one.name != null && this.moderateReport.activities.one.facialBegin.length && this.moderateReport.activities.one.facialEnd.length && this.moderateReport.activities.one.moodBegin.length && this.moderateReport.activities.one.moodEnd.length && this.moderateReport.activities.one.postureEnd && this.moderateReport.activities.one.postureBegin && this.moderateReport.activities.one.interestEnd.length && this.moderateReport.activities.one.interestBegin.length && this.moderateReport.activities.one.behaviourBegin.length && this.moderateReport.activities.one.behaviourEnd.length && this.moderateReport.activities.one.eyeContactBegin.length && this.moderateReport.activities.one.eyeContactEnd.length)) {
        return true;
      }
      return false;
    },
    activity2ModFilled() {
      if ((this.moderateReport.activities.two.name != '' && this.moderateReport.activities.two.behaviour.length && this.moderateReport.activities.two.mood.length && this.moderateReport.activities.two.participation.length && this.moderateReport.activities.two.thought.length && this.moderateReport.activities.two.alertness.length && this.moderateReport.activities.two.attention.length) || (this.moderateReport.activities.two.name != null && this.moderateReport.activities.two.facialBegin.length && this.moderateReport.activities.two.facialEnd.length && this.moderateReport.activities.two.moodBegin.length && this.moderateReport.activities.two.moodEnd.length && this.moderateReport.activities.two.postureEnd && this.moderateReport.activities.two.postureBegin && this.moderateReport.activities.two.interestEnd.length && this.moderateReport.activities.two.interestBegin.length && this.moderateReport.activities.two.behaviourBegin.length && this.moderateReport.activities.two.behaviourEnd.length && this.moderateReport.activities.two.eyeContactBegin.length && this.moderateReport.activities.two.eyeContactEnd.length)) {
        return true;
      }
      return false;
    },
    activity3ModFilled() {
      if ((this.moderateReport.activities.three.name != '' && this.moderateReport.activities.three.behaviour.length && this.moderateReport.activities.three.mood.length && this.moderateReport.activities.three.participation.length && this.moderateReport.activities.three.thought.length && this.moderateReport.activities.three.alertness.length && this.moderateReport.activities.three.attention.length) || (this.moderateReport.activities.three.name != null && this.moderateReport.activities.three.facialBegin.length && this.moderateReport.activities.three.facialEnd.length && this.moderateReport.activities.three.moodBegin.length && this.moderateReport.activities.three.moodEnd.length && this.moderateReport.activities.three.postureEnd && this.moderateReport.activities.three.postureBegin && this.moderateReport.activities.three.interestEnd.length && this.moderateReport.activities.three.interestBegin.length && this.moderateReport.activities.three.behaviourBegin.length && this.moderateReport.activities.three.behaviourEnd.length && this.moderateReport.activities.three.eyeContactBegin.length && this.moderateReport.activities.three.eyeContactEnd.length)) {
        return true;
      }
      return false;
    },
    activity4ModFilled() {
      if ((this.moderateReport.activities.four.name != '' && this.moderateReport.activities.four.behaviour.length && this.moderateReport.activities.four.mood.length && this.moderateReport.activities.four.participation.length && this.moderateReport.activities.four.thought.length && this.moderateReport.activities.four.alertness.length && this.moderateReport.activities.four.attention.length) || (this.moderateReport.activities.four.name != null && this.moderateReport.activities.four.facialBegin.length && this.moderateReport.activities.four.facialEnd.length && this.moderateReport.activities.four.moodBegin.length && this.moderateReport.activities.four.moodEnd.length && this.moderateReport.activities.four.postureEnd && this.moderateReport.activities.four.postureBegin && this.moderateReport.activities.four.interestEnd.length && this.moderateReport.activities.four.interestBegin.length && this.moderateReport.activities.four.behaviourBegin.length && this.moderateReport.activities.four.behaviourEnd.length && this.moderateReport.activities.four.eyeContactBegin.length && this.moderateReport.activities.four.eyeContactEnd.length)) {
        return true;
      }
      return false;
    },
    optionalCheckMild() {
      if (this.activity4Mild.isChecked && (this.mildReport.activities.four.name == '' || !this.mildReport.activities.four.behaviour.length || !this.mildReport.activities.four.mood.length || !this.mildReport.activities.four.thought.length || !this.mildReport.activities.four.participation.length || !this.mildReport.activities.four.alertness.length || !this.mildReport.activities.four.attention.length)) {
        return true;
      }
      return false;
    },
    optionalCheckModerate() {
      if ((this.activity2Moderate.isChecked && !this.activity2ModFilled) || (this.activity3Moderate.isChecked && !this.activity3ModFilled) || (this.activity4Moderate.isChecked && !this.activity4ModFilled)) {
        return true;
      }
      else {
        return false;
      }
    },
    allTempsFilledIn() {
      if (this.checkoutModal.performCheckout == false) {
        if (this.tempModal.clientTemp && this.tempModal.caregiversAndTemps.every(c => c.temp)) {
          return false;
        }
        return true;
      }
      else {
        if (this.tempModal.clientTemp && this.tempModal.clientOutTemp && this.tempModal.caregiversAndTemps.every(c => c.temp) && this.tempModal.caregiversAndTemps.every(c => c.tempOut)) {
          return false;
        }
        return true;
      }
    },
    clientfilled() {
      if (!this.attendance_info[0].crb5c_temperature_out || !this.attendance_info[0].crb5c_temperature) {
        return false;
      }
      return true;
    },
    caregiver1filled() {
      // console.log(this.attendance_info[0])
      if (this.attendance_info[0]._crb5c_caregiver_value && (!this.attendance_info[0].crb5c_caregiver_temp || !this.attendance_info[0].crb5c_caregiver_out_temp)) {
        return false;
      }
      return true;
    },
    caregiver2filled() {
      if (this.attendance_info[0]._crb5c_caregiver2_value && (!this.attendance_info[0].crb5c_caregiver2_temp || !this.attendance_info[0].crb5c_caregiver2_out_temp)) {
        return false;
      }
      return true;
    },
    caregiver3filled() {
      if (this.attendance_info[0]._crb5c_caregiver3_value && (!this.attendance_info[0].crb5c_caregiver3_temp || !this.attendance_info[0].crb5c_caregiver3_out_temp)) {
        return false;
      }
      return true;
    },
    caregiver4filled() {
      if (this.attendance_info[0]._crb5c_caregiver4_value && (!this.attendance_info[0].crb5c_caregiver3_temp || !this.attendance_info[0].crb5c_caregiver3_out_temp)) {
        return false;
      }
      return true;
    },
    nextBtn() {
      if (this.sessionReportModal.activityIndividual) {
        if (this.sessionReportModal.questionNum >= 0 && this.sessionReportModal.questionNum < 5) {
          return false;
        }
        else {
          return true;
        }
      }
      if (this.sessionReportModal.activity3) {
        if (this.sessionReportModal.questionNum >= 0 && this.sessionReportModal.questionNum < 4) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        if (this.sessionReportModal.questionNum >= 0 && this.sessionReportModal.questionNum < 6) {
          return false;
        }
        else {
          return true;
        }
      }
    },
    prevBtn() {
      if (this.sessionReportModal.questionNum > 0) {
        return false;
      }
      else {
        return true;
      }
    },
    incidentReportFilled() {
      if (this.emergencyModal.areaInput) {
        return false;
      }
      return true;
    },
    isFacialEnd() {
      if (this.sessionReportModal.facialBegin.length < 0) {
        // console.log("running")
        return true;
      }
      return false;
    },
    isEyeEnd() {
      if (this.sessionReportModal.eyeContactBegin < 0) {
        // console.log("running")
        return true;
      }
      return false;
    },
    isInterestEnd() {
      if (this.sessionReportModal.interestBegin) {
        return false;
      }
      return true;
    },
    isBodyEnd() {
      if (this.sessionReportModal.postureBegin) {
        return false;
      }
      return true;
    },
    isMoodEnd() {
      if (this.sessionReportModal.moodBegin) {
        return false;
      }
      return true;
    },
    isBehaviourEnd() {
      if (this.sessionReportModal.behaviourBegin) {
        return false;
      }
      return true;
    },
    maxCg() {
      if (this.crHighTempModal.totalCg == 4) {
        return true;
      }
      else {
        return false;
      }
    },
    previousSessionPage() {
      switch (this.sessionsPage.fe) {
        case 'rooms':
          return `Sessions Page`;
        case 'attendees':
          return `Rooms for ${this.sessionsPage.sessionName}`;
      }

      return 'asd';
    },
    allRooms() {
      return Object.entries(this.rooms).map(o => { return { id: o[0], name: o[1] } })
    },
    validRegistrationForm() {
      return true
    },
    validNric() {
      return this.checkValidNricCara(this.manualCheckedInModal.id?.toUpperCase())[0];
    },
    selectedCaregivers() {
      return this.checkedInModal.caregivers.filter(c => c.isChecked) || [];
    },
    ready() {
      return !this.promptLogin && this.logindata?.ready;
    },

  },



  components: {

    IonContent,
    IonRadio,
    IonRadioGroup,
    IonHeader,
    IonLabel,
    IonInput,
    IonModal,
    IonPage,
    IonTitle,
    IonToggle,
    IonGrid,
    IonButton,
    IonToolbar,
    IonAvatar,
    // IonGrid,
    // IonAlert,
    IonList,
    IonItem,
    IonCard,
    IonImg,
    IonCardContent,
    IonCheckbox,
    IonCardHeader,
    IonIcon,
    IonTextarea,
    // IonSelect,
    // IonSelectOption,
    FilePond,
    vSelect,
    StickyElement,
    IonDatetime,

  },
  //dataModel
  data() {
    return {
      attendance_info: [],
      haveToFillInTemp: false,
      mildActivity4Options: [
        { text: 'physicalActivities', id: 0 },
        { text: 'psysioActivities', id: 1 },
        { text: 'cognitiveActivities', id: 2 }
      ],
      moderateActivityOptions: [
        { text: 'physicalActivities', id: 0 },
        { text: 'psysioActivities', id: 1 },
        { text: 'cognitiveActivities', id: 2 },
        { text: 'namasteActivities', id: 3 },
        { text: 'individualActivities', id: 4 }
      ],
      filterDate: "",
      todayFilter: dayjs().tz(defaultTimezone).format('YYYY-MM-DD'),
      isFilter: false,
      optionsToFill: [],
      myFiles: [],
      myFiles2: [],
      show: true,
      isVisible: true,
      caregiversLimit: 4,
      tempModal: {
        attendanceId: null,
        show: false,
        activeClientSessionDetails: {},
        clientTemp: "",
        clientOutTemp: "",
        caregiversAndTemps: []
      },
      activeQuestion: null,
      dsgCheckInTitleImg: '/assets/images/DSG_logo.png',
      defaultUserImg: '/assets/images/user.png',
      defaultSessionImg: '/assets/images/session.png',
      sessionImage: '/assets/images/group.png',
      defaultRoomImg: '/assets/images/room.png',
      sessionDoorImage: '/assets/images/session_door.png',
      dsgLogo: '/assets/images/DSG_logo.png',
      rooms: {},
      activePage: 'scanning',
      allAttendees: [],
      storeVl: [],
      sessionsPage: {
        fe: 'main',
        sid: null,
        session: null,
        sessionName: null,
        room: null,
        roomName: null,
        client: null,
        clientName: null
      },
      registrationModal: {
        show: false,
        caregivers: []
      },
      links: {
        attendanceDashboardLink: 'https://dsg-fow-dev.crm5.dynamics.com/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entitylist&etn=crb5c_fowsessionattendance&viewid=17d8e938-94c1-ec11-983e-000d3ac885f2&viewType=1039'
      },
      subview: "client",
      i: allIcons,
      cr_name: "",
      cr_nric: "",
      //cara_id: "",
      logindata: { ready: false },
      msalApp: null,
      promptLogin: false,
      listener: null,
      scanholder: "",
      scanInProgress: false,
      checkedInModal: {
        id: '',
        show: false,
        sessions: [],
        sessions_today: [],
        sessions_today_checkout: [],
        client_name: null,
        client_id: null,
        checkedIn: null,
        caregivers: [],
        temp: "",
        sess_name: null,
        sessDuration: null,
        validCheckinTime: null,
        sessTime: null,

        clientStatus: null
      },
      manualCheckedInModal: {
        show: false,
        id: null
      },
      checkoutModal: {
        performCheckout: false,
        attsessionID: null,
        list: []
      },
      adhoclistModal: {
        show: false,
        pick: false,
        adSessionid: null,
        idClient: null,
        adhocsessions: [],
        dRoom: null
      },
      highTempModal: {
        show: false,
      },
      crHighTempModal: {
        show: false,
        caregivers: [],
        reserveCaregivers: [],
        client_id: null,
        session_id: null,
        subsCaregiver: [],
        totalCg: null,
        mode: null
      },
      emergencyModal: {
        show: false,
        areaInput: null,
        sessID: null
      },
      navigateToAttendanceModal: {
        show: false
      },
      sessionReportModal: {
        headerType: null,
        isNamasteCare: null,
        activityList: [],
        reportCompletion: null,
        physicalActivities: [],
        psysioActivities: [],
        cognitiveActivities: [],
        namasteActivities: [],
        individualActivities: [],
        show: false,
        uploadPicModal: false,
        showIndividual: false,
        sessID: null,
        clientDetail: [],
        questionNum: 0,
        date: null,
        activity: null,
        activityTypeList: [{ name: 'Physical', isChecked: false }, { name: 'Psycho-Socio', isChecked: false }, { name: 'Cognitive', isChecked: false }],
        activity1: [{ name: 'Music & Movement Exercise', img: '/assets/images/music_&_movement_exercise.jpg' }, { name: 'Fan Dancing', img: '/assets/images/fan_dancing.jpg' }, { name: 'Line Dancing', img: '/assets/images/line_dancing.jpg' }],
        activity2: [{ name: 'Painting', isChecked: false, img: '/assets/images/painting.jpg' }, { name: 'Baking', isChecked: false, img: '/assets/images/baking.jpg' }, { name: 'Vocal Training', isChecked: false, img: '/assets/images/vocal_training.jpg' }],
        activity3: [{ name: 'Painting', isChecked: false, img: '/assets/images/painting.jpg' }, { name: 'Baking', isChecked: false, img: '/assets/images/baking.jpg' }, { name: 'Vocal Training', isChecked: false, img: '/assets/images/vocal_training.jpg' }],
        activity4: [{ name: 'Baking', isChecked: false, img: '/assets/images/baking.jpg' }, { name: 'Vocal Training', isChecked: false, img: '/assets/images/vocal_training.jpg' }],
        activityModal: false,
        reportType: null,
        reportFormat: null,
        MildActivity4Choice: [],
        ModerateActivity1Choice: null,
        ModerateActivity2Choice: null,
        ModerateActivity3Choice: null,
        ModerateActivity4Choice: null,

        eligibleNamaste: null,
        reportSubmitBtn: "Save",
        indiNam: [{ name: 'Exercise', isChecked: false }, { name: 'Throw-ball', isChecked: false }, { name: 'Hitting Balloon', isChecked: false }, { name: 'Craft', isChecked: false }],
        indi: [{ name: 'Vocal Training', isChecked: false, img: '/assets/images/vocal_training.jpg' }, { name: 'Baking', isChecked: false, img: '/assets/images/baking.jpg' }, { name: 'Table-top', isChecked: false, img: '/assets/images/table-top.jpg' }],
      },
      mildReport: {
        behaviourOnArrivalMild: [],
        moodOnArrivalMild: [],
        behaviourOnEndMild: [],
        moodOnEndMild: [],
        activities: {
          one: {
            name: "",
            type: 0,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,

          },
          two: {
            name: "",
            type: 1,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
          },
          three: {
            name: "",
            type: 2,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
          },
          four: {
            name: "",
            type: null,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
          }
        },

        // behaviourActivty1Mild: [],
        // moodActivty1Mild: [],
        // thoughActivity1Mild: [],
        // participationActivity1Mild: [],
        // levelofAlertActivity1Mild: [],
        // attentionspanActivity1Mild: [],

        // behaviourActivty2Mild: [],
        // moodActivty2Mild: [],
        // thoughActivity2Mild: [],
        // participationActivity2Mild: [],
        // levelofAlertActivity2Mild: [],
        // attentionspanActivity2Mild: [],

        // behaviourActivty3Mild: [],
        // moodActivty3Mild: [],
        // thoughActivity3Mild: [],
        // participationActivity3Mild: [],
        // levelofAlertActivity3Mild: [],
        // attentionspanActivity3Mild: [],

        // behaviourActivty4Mild: [],
        // moodActivty4Mild: [],
        // thoughActivity4Mild: [],
        // participationActivity4Mild: [],
        // levelofAlertActivity4Mild: [],
        // attentionspanActivity4Mild: [],
      },

      moderateReport: {
        behaviourOnArrivalModerate: [],
        moodOnArrivalModerate: [],
        behaviourOnEndModerate: [],
        moodOnEndModerate: [],
        activities: {
          one: {
            name: "",
            type: null,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],

          },
          two: {
            name: "",
            type: null,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],

          },
          three: {
            name: "",
            type: null,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          },
          four: {
            name: "",
            type: null,
            behaviour: [

            ],
            mood: [

            ],
            thought: [

            ],
            participation: [

            ],
            alertness: [

            ],
            attention: [

            ],
            remark: null,
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          }
        },


        // behaviourOnArrivalModerate:[],
        // moodOnArrivalModerate:[],
        // behaviourOnEndlModerate:[],
        // moodOnEndModerate:[],

        // behaviourActivty1Moderate: [],
        // moodActivty1Moderate: [],
        // thoughActivity1Moderate: [],
        // participationActivity1Moderate: [],
        // levelofAlertActivity1Moderate: [],
        // attentionspanActivity1Moderate: [],

        // behaviourActivty2Moderate: [],
        // moodActivty2Moderate: [],
        // thoughActivity2Moderate: [],
        // participationActivity2Moderate: [],
        // levelofAlertActivity2Moderate: [],
        // attentionspanActivity2Moderate: [],

        // behaviourActivty3Moderate: [],
        // moodActivty3Moderate: [],
        // thoughActivity3Moderate: [],
        // participationActivity3Moderate: [],
        // levelofAlertActivity3Moderate: [],
        // attentionspanActivity3Moderate: [],

        // behaviourActivty4Moderate: [],
        // moodActivty4Moderate: [],
        // thoughActivity4Moderate: [],
        // participationActivity4Moderate: [],
        // levelofAlertActivity4Moderate: [],
        // attentionspanActivity4Moderate: [],

      },

      namasteCare: {
        behaviourOnArrivalNamaste: [],
        moodOnArrivalNamaste: [],
        behaviourOnEndNamaste: [],
        moodOnEndNamaste: [],
        activities: {
          one: {
            name: "TestNamaste1",
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          },
          two: {
            name: "",
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          },
          three: {
            name: "",
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          },
          four: {
            name: "",
            type: 0,
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
          }
        },
      },

      activity4Mild: [{ isChecked: false }],
      activity1Moderate: [{ isChecked: false }],
      activity2Moderate: [{ isChecked: false }],
      activity3Moderate: [{ isChecked: false }],
      activity4Moderate: [{ isChecked: false }],

      activityPhysical: [{ isChecked: false }],
      activityPsychoSocio: [{ isChecked: false }],
      activityCognitive: [{ isChecked: false }],

      behaviourOptions: [{ label: 'Cooperative', img: '/assets/expressionIcon/happy.png', id: 0 }, { label: 'Uncooperative', img: '/assets/expressionIcon/dissapointed.png', id: 1 }, { label: 'Calm', img: '/assets/expressionIcon/satisfied.png', id: 2 }, { label: 'Restless', img: '/assets/expressionIcon/tired.png', id: 3 }, { label: 'Agitated', img: '/assets/expressionIcon/tired_2.png', id: 4 }, { label: 'Aggressive', img: '/assets/expressionIcon/angry.png', id: 5 }],
      behaviour2: ['Calm', 'Restless', 'Agitated', 'Aggressive'],
      moodOptions: [{ label: 'Elated', img: '/assets/expressionIcon/Elated.png', id: 0 }, { label: 'Happy', img: '/assets/expressionIcon/happy.png', id: 1 }, { label: 'Neutral', img: '/assets/expressionIcon/neutral.png', id: 2 }, { label: 'Irritable', img: '/assets/expressionIcon/dissapointed.png', id: 3 }, { label: 'Angry', img: '/assets/expressionIcon/angry.png', id: 4 }, { label: 'Anxious', img: '/assets/expressionIcon/shocked.png', id: 5 }, { label: 'Sad', img: '/assets/expressionIcon/sad.png', id: 6 }, { label: 'Depressed', img: '/assets/expressionIcon/frustrated.png', id: 7 }],
      thoughtOptions: [{ label: 'Relevant', img: '/assets/expressionIcon/smiling.png', id: 0 }, { label: 'Irrelevant', img: '/assets/expressionIcon/raised_eyebrow.png', id: 1 }, { label: 'Inconsistent', img: '/assets/expressionIcon/questioning.png', id: 2 }, { label: 'Preoccupied', img: '/assets/expressionIcon/squinting.png', id: 3 }, { label: 'Delusional', img: '/assets/expressionIcon/drunk.png', id: 4 }],
      participationOptions: [{ label: 'Yes', img: '/assets/expressionIcon/smiling.png', id: 0 }, { label: 'Sometimes', img: '/assets/expressionIcon/neutral.png', id: 1 }, { label: 'Never', img: '/assets/expressionIcon/worried.png', id: 2 }],
      levelofAlertOptions: [{ label: 'Alert', img: '/assets/expressionIcon/happy.png', id: 0 }, { label: 'Lethargic', img: '/assets/expressionIcon/tired.png', id: 1 }, { label: 'Fluctuating', img: '/assets/expressionIcon/fed_up.png', id: 2 }],
      attentionspanOptions: [{ label: 'Good (> 30 min)', img: '/assets/expressionIcon/happy.png', id: 0 }, { label: 'Fair (15-30 min)', img: '/assets/expressionIcon/smiling.png', id: 1 }, { label: 'Poor ( < 15 min)', img: '/assets/expressionIcon/neutral.png', id: 2 }],
      facialOptions: [{ label: 'Passive', id: 0 }, { label: 'Grimacing', id: 1 }, { label: 'Frowning', id: 2 }, { label: 'Frightened', id: 3 }, { label: 'Smilling', id: 4 }],
      eyeContactOptions: [{ label: 'Closed eye', id: 0 }, { label: 'Looking away', id: 1 }, { label: 'Passive', id: 2 }, { label: 'Blank look', id: 3 }, { label: 'Little eye contact', id: 4 }, { label: 'Good eye contact', id: 5 }],
      interestOptions: [{ label: 'Avoiding', id: 0 }, { label: 'Listening', id: 1 }, { label: 'Non-verbal response', id: 2 }, { label: 'Unclear verbal response', id: 3 }, { label: 'Clear verbal response', id: 4 }],
      postureOptions: [{ label: 'Restless', id: 0 }, { label: 'Tense', id: 1 }, { label: 'Slouched', id: 2 }, { label: 'Relaxed', id: 3 }, { label: 'Jerky', id: 4 }],
      checkOutMode: false,
      fromCheckIn: false,
      fetchingAllSessions: false,
      allSessionsToday: [],
      config: [],
      configYesNoOptions: [{
        label: 'Yes',
        value: 'Yes'
      }, {
        label: 'No',
        value: 'No'
      }],
      ConfigType,
      updatingConfig: false,
      reInitInterval: null
    };
  },
  created() {
    this.reInitInterval = setInterval(() => (this.init(), console.log('reinit')), 60000 * 4)
    window.onfocus = async () => {
      if (this.sessionsPage.fe == 'attendees') {
        await this.refreshpage();
      }
    }

  },
  async mounted() {
    const { data: { config } } = await this.init();
    this.config = config;
    this.config.forEach((val) => {
      this.$watch(() => val, this.updateConfig, { deep: true });
    });
    this.processConfig();
    this.listener = (ev) => {
      // console.log('eve =',ev);
      this.scanholder += ev.key.length == 1 ? ev.key : "";
      if (!this.scanInProgress) {
        this.scanInProgress = true;
        debounce(() => {
          this.scanInProgress = false;
          this.scanholder = "";
        }, 2000)();

      }
      if (this.scanInProgress && ev.key == "Enter") {
        try {
          let url = new URL(this.scanholder)
          let path = url.pathname;
          let caracodeArr = path.split("/");
          let code = caracodeArr.pop();
          if (code) {
            this.showClient(code);
          }
        } catch (err) {
          //not a cara code
        }
        const scannedNric = this.checkValidNricCara(this.scanholder)[0];
        const cleanedNricCara = this.checkValidNricCara(this.scanholder)[1];
        if (scannedNric) {
          this.scanholder = cleanedNricCara;
          this.showClient(cleanedNricCara, false);
          this.manualCheckedInModal.show = false;
        }
        this.scanInProgress = false;
        this.scanholder = "";
      }
    };
    window.addEventListener("keypress", this.listener);
  },

  methods: {
    processConfig() {
      for (let config of this.config) {
        if (config.crb5c_name === 'temperatureRequired') {
          if (config.crb5c_value.toLowerCase() === "no") {
            this.haveToFillInTemp = false;
          } else {
            this.haveToFillInTemp = true;
          }
        }
      }
    },
    async updateConfig(val) {
      try {
        this.updatingConfig = true;
        let { data } = await this.$store.state.axios.patch(
          `/crb5c_configs(${val.crb5c_configid})`, {
          crb5c_value: val.crb5c_value
        }
        );
        if (data.crb5c_configid) {
          this.processConfig();
          alert(`Configuration: "${val.crb5c_configurationname}" updated!`);
          this.updatingConfig = false;
        }
      }
      catch (e) {
        alert(`Error: ${e?.response?.data}`);
      }

    },
    checkValidNricCara(input) {
      let finalInput = input;
      let nricTest = /^[STFGstfg]\d{7}[A-Za-z]$/g.test(input);
      const caraTest = /^[A-Z]\d{3}[A-Z]\d{5}$/g.test(input);

      if (!nricTest && input?.length > 10) {
        finalInput = input?.slice(0, 9); //For too much info NRICs
        nricTest = /^[STFGstfg]\d{7}[A-Za-z]$/g.test(finalInput);
      }
      return [(nricTest || caraTest), finalInput];
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log('data', formData, index, fileList)
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      }
    },
    editImage(formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },

    async showAdHocList() {
      let paramObj = {
        $filter: `crb5c_date ge '${dayjs().format('YYYY-MM-DD')}' and crb5c_date lt '${dayjs().add(1, 'day').format('YYYY-MM-DD')}' and crb5c_allowadhoccheckin eq true`
      };
      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `/crb5c_fow_sessions?${params.toString()}`
      );

      this.adhoclistModal.adhocsessions = clientData.value;
      this.adhoclistModal.show = true;
      // console.log(this.adhoclistModal.adhocsessions);

    },
    submitFullReport() {
      // console.log(this.myFiles)
      // console.log(this.myFiles2)
      this.sessionReportModal.uploadPicModal = false;
    },
    async pickadhoc(index) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirmation',
          message: `<strong>Client is about to be assigned to '${this.adhoclistModal.adhocsessions[index].crb5c_sessionname}' session?</strong>`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
            },
            {
              text: 'Proceed',
              id: 'confirm-button',
              handler: blah => {
                this.adhoclistModal.adSessionid = this.adhoclistModal.adhocsessions[index].crb5c_fow_sessionid;
                this.assignClienttoAdhoc()
                console.log('Confirm Okay', blah)
              },
            },
          ],
        });
      return alert.present();

    },
    async assignClienttoAdhoc() {

      let paramObj2 = {
        $select: "crb5c_defaultroom,crb5c_nricno",
        $filter: `crb5c_fow_customerid eq '${this.checkedInModal.client_id}'`
      };
      let params2 = new URLSearchParams(paramObj2);
      let { data: clientData2 } = await this.$store.state.axios.get(
        `/crb5c_fow_customers?${params2.toString()}`);
      let rm = clientData2.value;

      let sessionID = this.adhoclistModal.adSessionid;
      let client_id = this.checkedInModal.client_id;
      let client_room = rm[0].crb5c_defaultroom;
      let client_ic = rm[0].crb5c_nricno;

      const payload = {
        "crb5c_session@odata.bind": `/crb5c_fow_sessions(${sessionID})`,
        "crb5c_client@odata.bind": `/crb5c_fow_customers(${client_id})`,
        crb5c_room: client_room
      }

      const { data } = await this.$store.state.axios.post('/crb5c_fowsessionattendances', payload);
      this.clearmodal();
      console.log("success", data)
      if (client_ic) {
        this.showClient(client_ic, false);
      } else {
        alert("Unable to check-in client automatically, please scan or enter IC to check-in!");
      }
    },
    async bulkCheckout() {

      const proceed = confirm('Are you sure you want to check-out everyone here?');

      if (!proceed) {
        return;
      }

      const notCheckedOut = this.allAttendees.filter(a => !a.crb5c_checkout_time);

      for (let a of notCheckedOut) {
        await this.doCheckOut(a.crb5c_fowsessionattendanceid, true);
      }

      alert('Everyone in this session room has been checked out!');
      await this.fetchClientsInSession(this.sessionsPage.room);
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
    async showAttendeeActionSheet(attendeeObj) {
      console.log(attendeeObj, "attendy proxy")
      //await this.$nextTick();
      this.sessionsPage.client = attendeeObj.crb5c_client.crb5c_fow_customerid;
      this.sessionsPage.clientName = attendeeObj.crb5c_client.crb5c_no;
      const buttons = [];
      const tempBtn = {
        text: 'Check-in/out temperature',
        icon: this.i.thermometer,
        data: 10,
        handler: async () => {
          //await this.fetchClientsInSession(this.sessionsPage.room);
          console.log(this.sessionsPage.room, "room id sheet")
          this.tempModal.activeClientSessionDetails = JSON.parse(JSON.stringify(attendeeObj));
          this.tempModal.clientTemp = attendeeObj.crb5c_temperature;
          this.tempModal.clientOutTemp = attendeeObj.crb5c_temperature_out;
          this.tempModal.attendanceId = attendeeObj.crb5c_fowsessionattendanceid;
          const caregiversAndTemps = [];

          for (let i = 1; i <= this.caregiversLimit; i++) {
            const currentIteration = i == 1 ? '' : i;
            if (attendeeObj['crb5c_caregiver' + currentIteration]) {
              caregiversAndTemps.push({
                id: attendeeObj['crb5c_caregiver' + currentIteration].crb5c_fow_caregiverid,
                name: attendeeObj['crb5c_caregiver' + currentIteration].crb5c_name,
                temp: attendeeObj['crb5c_caregiver' + currentIteration + '_temp'],
                tempOut: attendeeObj['crb5c_caregiver' + currentIteration + '_out_temp'],
              })
            }
          }

          this.tempModal.caregiversAndTemps = caregiversAndTemps;
          this.tempModal.show = true;
          // console.log(caregiversAndTemps);
          this.checkoutModal.performCheckout = false;
          this.sessionReportModal.activityModal = false;




        },
      };
      const checkOutBtn = {
        text: 'Check-out',
        icon: this.i.checkmark,
        data: 'Data value',
        handler: async () => {
          await this.doCheckOut(attendeeObj.crb5c_fowsessionattendanceid ? attendeeObj.crb5c_fowsessionattendanceid : null, false, attendeeObj);
          await this.fetchClientsInSession(this.sessionsPage.room);
          // console.log(this.sessionsPage.room)
        },
      }
      const emergencyBtn = {
        text: 'Incident Report',
        icon: this.i.ban,
        data: 'Data value',
        handler: async () => {
          this.emergencyModal.show = true;
          this.emergencyModal.sessID = attendeeObj.crb5c_fowsessionattendanceid;
        },
      }

      const reportBtn = {
        text: 'Session Report',
        icon: this.i.pencilOutline,
        data: 'Data value',
        handler: async () => {

          let reportCompletion = attendeeObj.crb5c_reportstatus;
          if (reportCompletion == "Done") {
            this.sessionReportModal.sessID = attendeeObj.crb5c_fowsessionattendanceid;
            this.navigateToAttendanceModal.show = true;
          }
          else {
            const local = window.location.host.includes('localhost');
            window.open(`${local ? 'http://localhost:8008' : 'https://fow-session-test.npo.sg'}/home/?id=${attendeeObj.crb5c_fowsessionattendanceid}&client_id=${attendeeObj.crb5c_client.crb5c_fow_customerid}`);
            return;
          }
          // else {
          //   this.sessionReportModal.clientDetail = attendeeObj;
          //   // console.log(this.sessionReportModal.clientDetail)
          //   this.sessionReportModal.reportType = this.sessionReportModal.clientDetail.crb5c_session.crb5c_sessionreporttype;
          //   this.sessionReportModal.date = dayjs();
          //   // console.log(this.sessionReportModal.clientDetail.crb5c_client.crb5c_no)
          //   // console.log(dayjs().utc().toISOString(), dayjs().format("DD/MM/YYYY"))
          //   this.sessionReportModal.sessID = attendeeObj.crb5c_fowsessionattendanceid;
          //   this.updateActivityList();
          //   this.sessionReportModal.show = true;

          //   switch (this.sessionReportModal.reportType) {
          //     case 0:
          //       this.sessionReportModal.reportFormat = "mildReport";
          //       this.sessionReportModal.headerType = "Mild";
          //       break;
          //     case 1:
          //       this.sessionReportModal.reportFormat = "moderateReport";
          //       this.sessionReportModal.headerType = "Moderate";
          //       break;
          //     case 2:
          //       this.sessionReportModal.reportFormat = "moderateReport";
          //       this.sessionReportModal.headerType = "Moderate";
          //       break;
          //     case 3:
          //       this.sessionReportModal.reportFormat = "moderateReport";
          //       this.sessionReportModal.headerType = "Moderate";
          //       break;
          //   }
          // }

        },
      }
      const cancelBtn = {
        text: 'Cancel',
        icon: this.i.close,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked')
        },
      };

      if (!attendeeObj.crb5c_checkout_time) {
        buttons.push(tempBtn);
        buttons.push(checkOutBtn);
        buttons.push(emergencyBtn);
      }

      if (attendeeObj.crb5c_checkout_time) {
        buttons.push(reportBtn);
      }

      buttons.push(cancelBtn);
      const actionSheet = await actionSheetController
        .create({
          header: 'Action',
          cssClass: 'my-custom-class',
          buttons
        });

      await actionSheet.present();
      await this.fetchClientsInSession(this.sessionsPage.room);

      const { role, data, } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role and data', role, data);
    },
    imageLink(clientObj) {
      if (clientObj?.crb5c_photo) {
        return ' data:image/jpeg;charset=utf-8;base64,' + clientObj.crb5c_photo;
      }

      return this.defaultUserImg;
    },
    async refreshpage() {
      await this.fetchClientsInSession(this.sessionsPage.room);
    },
    nextSlide() {
      this.$refs.slides.slideNext();
    },
    async backSession() {
      switch (this.sessionsPage.fe) {
        case 'rooms':
          this.sessionsPage.fe = 'main';
          await this.fetchAllSessionsToday(this.filterDate);
          return;
        case 'main':
          return;
        case 'attendees':
          this.sessionsPage.fe = 'rooms';
          return;
      }
    },
    filterSession() {
      this.isFilter = true;
    },
    async setSessionAndProceedToRooms(sid, sname) {
      this.sessionsPage.sid = sid;
      this.sessionsPage.sessionName = sname;
      // console.log(this.sessionsPage)
      const { data: globalx } = await this.$store.state.axios.get(`GlobalOptionSetDefinitions(9e739642-91c1-ec11-983e-000d3ac88352)`);
      const rooms = {};

      for (let opt of globalx.Options) {
        rooms[opt.Value] = opt.Label.UserLocalizedLabel.Label
      }
      this.rooms = rooms;
      this.sessionsPage.fe = 'rooms';

    },
    async fetchClientsInSession(room_id) {
      this.sessionsPage.room = room_id;
      let ifNull = '';
      this.sessionsPage.roomName = this.rooms[room_id];

      if (this.rooms[room_id] === 'No Room') {
        ifNull = 'or crb5c_room eq null';
      }

      let paramObj = {
        //$select: "crb5c_sessionname",
        $expand: `crb5c_client,crb5c_session,crb5c_caregiver,crb5c_caregiver2,crb5c_caregiver3,crb5c_caregiver4`,//($select=crb5c_client)
        //$filter: is_cara_id ? `crb5c_cara_id eq '${membershipId}'`:`crb5c_fow_customerid eq '${user_id}'`,
        $filter: `(crb5c_room eq ${room_id}${ifNull}) and crb5c_session/crb5c_fow_sessionid eq '${this.sessionsPage.sid}'`
      };

      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `crb5c_fowsessionattendances/?${params.toString()}`
      );

      this.allAttendees = clientData.value;

      await this.$nextTick();
      this.sessionsPage.fe = 'attendees';
    },
    async openActivePage() {
      this.activePage = 'sessionsToday';
      this.sessionsPage.fe = 'main';
      this.fetchingAllSessions = true;
      await this.fetchAllSessionsToday(this.filterDate);
    },
    async fetchAllSessionsToday(val) {
      try {
        if (!this.$store.state.axios?.get) {
          await this.$root.prepareAxios();
        }
        console.log('val', val)
        if (val === "Invalid Date" || val == "") {
          val = "";
          this.filterDate = "";
        } else {
          this.filterDate = dayjs(val).tz(defaultTimezone).format('YYYY-MM-DD');
        }

        

        if (val) {
          let paramObj = {
            $filter: `crb5c_date ge '${dayjs(val).tz(defaultTimezone).format('YYYY-MM-DD')}' and crb5c_date lt '${dayjs(val).tz(defaultTimezone).add(1, 'day').format('YYYY-MM-DD')}'`
          };
          let params = new URLSearchParams(paramObj);
          try {
            let { data: clientData } = await this.$store.state.axios.get(
              `/crb5c_fow_sessions?${params.toString()}`
            );
            this.fetchingAllSessions = false;
            this.allSessionsToday = clientData.value;
            this.isFilter = false;
          } catch (e) {
            if (e?.response?.status > 400) {
              alert('Session expired. Reloading the page. Please try again.');
              window.location.reload
            }
          }
          // console.log(clientData.value)

        }
        else {

          let paramObj = {
            //$select: "crb5c_allowadhoccheckin,crb5c_sessionname",
            //$expand:`crb5c_client,crb5c_session`,//($select=crb5c_client)
            //$filter: is_cara_id ? `crb5c_cara_id eq '${membershipId}'`:`crb5c_fow_customerid eq '${user_id}'`,
            $filter: `crb5c_date ge '${dayjs().tz(defaultTimezone).format('YYYY-MM-DD')}' and crb5c_date lt '${dayjs().tz(defaultTimezone).add(1, 'day').format('YYYY-MM-DD')}'`
          };

          let params = new URLSearchParams(paramObj);
          let { data: clientData } = await this.$store.state.axios.get(
            `/crb5c_fow_sessions?${params.toString()}`
          )
          this.fetchingAllSessions = false;
          this.allSessionsToday = clientData.value;
        }
      } catch (e) {
        console.log('Was error on Fetching All. retrying.');
        await this.init();
        await this.fetchAllSessionsToday(val);
      }
    },
    addCaregivers() {
      const res = prompt('Please input the caregiver name');
      if (res) {
        this.registrationModal.caregivers.push(res);
      }
    },
    linkOut(link) {
      window.open(link, '_blank');
    },
    async addCaregiver(client_id) {
      try {
        const result = prompt('Enter the new Caregiver name');
        if (!result?.trim()) {
          return;
        }
        const payload = {
          crb5c_name: result.trim(),
          "crb5c_client@odata.bind": `/crb5c_fow_customers(${client_id})`
        }

        const { data } = await this.$store.state.axios.post('/crb5c_fow_caregivers', payload);
        this.checkedInModal.caregivers.push({
          id: data.crb5c_fow_caregiverid,
          name: data.crb5c_name,
          isChecked: true
        });
      } catch (e) {
        console.log('Was error on addCaregiver. Retrying.');
        await this.init();
        await this.addCaregiver(client_id);
      }
    },
    async fillTempModal(session_id) {
      let paramObj2 = {
        $expand: `crb5c_client,crb5c_session,crb5c_caregiver,crb5c_caregiver2,crb5c_caregiver3,crb5c_caregiver4`,
        $filter: `crb5c_fowsessionattendanceid eq '${session_id}'`
      };
      let params2 = new URLSearchParams(paramObj2);
      let { data: clientData } = await this.$store.state.axios.get(`/crb5c_fowsessionattendances?${params2.toString()}`);
      let attendance_info = clientData.value;
      // console.log(attendance_info)
      this.tempModal.activeClientSessionDetails = JSON.parse(JSON.stringify(attendance_info[0]));
      this.tempModal.clientTemp = attendance_info[0].crb5c_temperature;
      this.tempModal.clientOutTemp = attendance_info[0].crb5c_temperature_out;
      this.tempModal.attendanceId = attendance_info[0].crb5c_fowsessionattendanceid;
      this.checkoutModal.list = attendance_info;

      const caregiversAndTemps = [];
      for (let i = 1; i <= this.caregiversLimit; i++) {
        const currentIteration = i == 1 ? '' : i;
        if (attendance_info[0]['crb5c_caregiver' + currentIteration]) {
          caregiversAndTemps.push({
            id: attendance_info[0]['crb5c_caregiver' + currentIteration].crb5c_fow_caregiverid,
            name: attendance_info[0]['crb5c_caregiver' + currentIteration].crb5c_name,
            temp: attendance_info[0]['crb5c_caregiver' + currentIteration + '_temp'],
            tempOut: attendance_info[0]['crb5c_caregiver' + currentIteration + '_out_temp'],
          })
        }
      }
      this.tempModal.caregiversAndTemps = caregiversAndTemps;
    },
    async submitTemp() {
      console.log('perform checkout', this.checkoutModal.performCheckout)
      //const clientTempHigh = (c) => c.temp > 37.5;

      let cgHigh = false;
      if (this.tempModal.caregiversAndTemps.length) {
        for (let i = 0; i < this.tempModal.caregiversAndTemps.length; i++) {
          // console.log(this.tempModal.caregiversAndTemps[i].temp)
          if (this.tempModal.caregiversAndTemps[i].temp > 37.5)
            cgHigh = true;
        }
      }

      if (this.tempModal.clientTemp > 37.5) {
        this.highTempModal.show = true;
      }
      else if (cgHigh) {
        let x = [];
        for (let cg of this.tempModal.caregiversAndTemps) {
          if (cg.temp > 37) {
            x.push(cg.id);
          }
        }
        this.runCareTempCheck(this.tempModal.attendanceId, x);
      }
      else {
        const proceed = confirm('Are you sure you want to submit?');
        if (!proceed) {
          return;
        }
        if (this.tempModal.clientOutTemp > 37.5) {
          alert('Client has high temperature during checkout!')
        }
        const payload = {
          crb5c_temperature: this.tempModal.clientTemp,
          crb5c_temperature_out: this.tempModal.clientOutTemp
        }

        let i = 1;
        for (let cg of this.tempModal.caregiversAndTemps) {
          payload[`crb5c_caregiver${i === 1 ? '' : i}_temp`] = cg.temp
          payload[`crb5c_caregiver${i === 1 ? '' : i}_out_temp`] = cg.tempOut
          //payload[`crb5c_caregiver${i ? i+1 : ''}@odata.bind`] =  `/crb5c_fow_caregivers(${this.tempModal.caregiversAndTemps[i].id})`
          i++;
        }
        const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${this.tempModal.attendanceId})`, payload)
        console.log(data);
        this.clearmodal();
        alert('Temperature information saved');
        this.tempModal.show = false;
        this.finalizecheckout();
      }
    },
    async finalizecheckout() {
      if (this.checkoutModal.performCheckout == true) {
        const proceed = confirm('Are you sure you want to check-out this client?');
        if (!proceed) {
          return;
        }
        const payload = {
          crb5c_checkout_time: dayjs().utc().toISOString(),
        }
        console.log("checkount id", this.checkoutModal.attsessionID)
        const { data } = await this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${this.checkoutModal.attsessionID})`, payload)
        console.log(data);
        this.tempModal.show = false;
        this.checkedInModal.show = false;
        this.checkedInModal.temp = "";
        alert('Client has been checked out!');
        await this.fetchClientsInSession(this.sessionsPage.room)
        this.checkoutModal.performCheckout = false;
        this.checkoutModal.attsessionID = null;
      }
      else {
        await this.fetchClientsInSession(this.sessionsPage.room)
      }
    },
    checktemp() {
      if (this.clientfilled && this.caregiver1filled && this.caregiver2filled && this.caregiver3filled && this.caregiver4filled) {
        return true;
      }
      return false;
    },
    async doCheckOut(session_id, bulk = false, attendeeObj) {
      let paramObj2 = {
        $filter: `crb5c_fowsessionattendanceid eq '${session_id}'`
      };
      let params2 = new URLSearchParams(paramObj2);
      let { data: clientData } = await this.$store.state.axios.get(`/crb5c_fowsessionattendances?${params2.toString()}`);
      this.attendance_info = clientData.value;
      this.checkoutModal.performCheckout = true;
      this.checkoutModal.list = attendeeObj;
      this.checkoutModal.attsessionID = session_id;

      let isfilled = this.checktemp();

      if (this.haveToFillInTemp && !isfilled) {
        this.promptTempModal(session_id);
        return;
      }
      else {
        if (!bulk) {
          const proceed = confirm('Are you sure you want to check-out this client?');
          if (!proceed) {
            return;
          }
          const payload = {
            crb5c_checkout_time: dayjs().utc().toISOString(),
          }
          const { data } = await this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
          console.log(data);
          this.checkedInModal.show = false;
          this.checkedInModal.temp = "";
          this.checkoutModal.performCheckout = false;
          this.checkoutModal.attsessionID = null;
          if (!bulk) {
            alert('Client has been checked out!');
          }
        }
      }
    },
    promptTempModal(session_id) {
      const result = confirm('Fill in the client (and caregiver) temperature before check-out.');
      if (result) {
        console.log('session_id:', session_id)
        this.fillTempModal(session_id);
        this.checkedInModal.show = false;
        this.tempModal.show = true;
      }
    },
    openTempModal(session_id) {
      console.log('id: ', this.checkedInModal.id)
      this.fillTempModal(session_id);
      this.checkedInModal.show = false;
      this.tempModal.show = true;
    },
    async doMainCheckOut(session_id, bulk = false) {
      let paramObj2 = {
        $expand: `crb5c_client,crb5c_session,crb5c_caregiver,crb5c_caregiver2,crb5c_caregiver3,crb5c_caregiver4`,
        $filter: `crb5c_fowsessionattendanceid eq '${session_id}'`
      };
      let params2 = new URLSearchParams(paramObj2);
      let { data: clientData } = await this.$store.state.axios.get(`/crb5c_fowsessionattendances?${params2.toString()}`);
      this.attendance_info = clientData.value;

      this.tempModal.activeClientSessionDetails = JSON.parse(JSON.stringify(this.attendance_info[0]));
      this.tempModal.clientTemp = this.attendance_info[0].crb5c_temperature;
      this.tempModal.clientOutTemp = this.attendance_info[0].crb5c_temperature_out;
      this.tempModal.attendanceId = this.attendance_info[0].crb5c_fowsessionattendanceid;
      this.checkoutModal.list = this.attendance_info;
      this.checkoutModal.performCheckout = true;
      this.checkoutModal.attsessionID = session_id;

      let isfilled = this.checktemp();

      if (this.haveToFillInTemp && !isfilled) {
        this.promptTempModal(session_id);
        return;
      }
      else {
        if (!bulk) {
          const proceed = confirm('Are you sure you want to check-out this client?');
          if (!proceed) {
            return;
          }
          const payload = {
            crb5c_checkout_time: dayjs().utc().toISOString(),
          }
          const { data } = await this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
          console.log(data);
          this.checkedInModal.show = false;
          this.checkedInModal.temp = "";
          this.checkoutModal.performCheckout = false;
          this.checkoutModal.attsessionID = null;
          if (!bulk) {
            alert('Client has been checked out!');
            if (this.fromCheckIn) {
              this.checkOutMode = false;
              this.fromCheckIn = false;
            }
          }
        }
      }

    },
    async runCareTempCheck(session_id, x) {
      let obj = {
        $expand: `crb5c_client,crb5c_session,crb5c_caregiver,crb5c_caregiver2,crb5c_caregiver3,crb5c_caregiver4`,
        $filter: `crb5c_fowsessionattendanceid eq '${session_id}'`
      };
      let params = new URLSearchParams(obj);
      let { data: clientData } = await this.$store.state.axios.get(`/crb5c_fowsessionattendances?${params.toString()}`);
      let attendance_info = clientData.value;
      this.tempModal.activeClientSessionDetails = JSON.parse(JSON.stringify(attendance_info[0]));
      this.tempModal.attendanceId = attendance_info[0].crb5c_fowsessionattendanceid;
      let client_id = attendance_info[0]._crb5c_client_value;

      let obj2 = {
        $select: "crb5c_no",
        $expand:
          "crb5c_fow_caregiver_client_crb5c_FOW_Cust($select=crb5c_name)",
        $filter: `crb5c_fow_customerid eq '${client_id}'`,
      };
      let params2 = new URLSearchParams(obj2);

      let clientDatas;
      try {
        let result = await this.$store.state.axios.get(
          `/crb5c_fow_customers?${params2.toString()}`
        );

        result.data = clientDatas;

      } catch (e) {
        alert('Error: ' + e);
      }

      // console.log(clientDatas)
      let client = clientDatas.value[0];
      // console.log(client)

      let caregivers = client.crb5c_fow_caregiver_client_crb5c_FOW_Cust.map(
        (cg) => {
          return {
            id: cg.crb5c_fow_caregiverid,
            name: cg.crb5c_name,
            isChecked: false
          };
        }
      );
      this.crHighTempModal.totalCg = caregivers.length;
      // console.log(this.crHighTempModal.totalCg)
      this.crHighTempModal.caregivers = caregivers;

      let reserve_caregivers = this.crHighTempModal.caregivers.filter(function (c) {
        return x.indexOf(c.id) === -1;
      });
      // console.log(reserve_caregivers);
      this.crHighTempModal.reserveCaregivers = reserve_caregivers;
      this.crHighTempModal.client_id = client_id;
      this.crHighTempModal.session_id = session_id;
      this.crHighTempModal.show = true;
    },
    focusInput(ref) {
      this.$nextTick(() => {
        console.log(this.$refs[ref]);
        this.$refs?.[ref]?.$el?.setFocus?.();
      });
    },
    showManualCheckInModal() {
      this.manualCheckedInModal.show = true;
    },
    async checkoutPage() {
      this.$router.push({ path: '/checkOut' });
    },
    async confirmCheckIn(session_id) {
      if (this.checkedInModal.temp > 37) {
        const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'High Temperature alert!',
            message: '<strong>Client is about to checkin with an abnormal temperature, do you wish to proceed?</strong>',
            buttons: [
              {
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  this.failedCheckIn(session_id)
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Proceed',
                id: 'confirm-button',
                handler: blah => {
                  this.confirmCheckinAlert(session_id)
                  console.log('Confirm Okay', blah)
                },
              },
            ],
          });
        return alert.present();
      } else {
        // console.log("runCheckinAlert")
        this.confirmCheckinAlert(session_id);
      }
    },
    async EmergencyReport(session_id) {
      const proceed = confirm('Are you sure to submit incident report and check-out client?');
      if (!proceed) {
        return;
      }
      const payload = {
        crb5c_reportforsession: this.emergencyModal.areaInput,
        crb5c_isemergency: "Yes",
        crb5c_checkout_time: dayjs().utc().toISOString(),
      }
      const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
      // console.log(session_id)
      // console.log(payload)
      console.log(data)
      alert('Incident report is submitted and client was checked-out');
      this.clearmodal();
      await this.fetchClientsInSession(this.sessionsPage.room);
      //this.$router.go()

    },
    async TempCheck(session_id, index) {
      if (index == 0) {
        const proceed = confirm("Are you sure to proceed?");
        if (proceed) {
          const payload = {
            crb5c_reportforsession: "Temperature recorded " + this.tempModal.clientTemp + " is higher than 37.5 degrees after 2 readings, client was asked to go home and picked up by caregiver. Staff attended to client until main caregiver arrived after.",
            crb5c_checkout_time: dayjs().utc().toISOString(),
            crb5c_iscancelled: "Yes"
          }
          const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
          console.log(data);
          alert('Client was removed from room and remark has been added');
          this.clearmodal();
          this.checkedInModal.temp = "";
          this.tempModal.show = false;
          await this.fetchClientsInSession(this.sessionsPage.room);
          //this.$router.go()
        }
      }
      else if (index == 1) {
        const proceed = confirm("Are you sure to proceed?");
        if (proceed) {
          const payload = {
            crb5c_reportforsession: "Temperature recorded " + this.tempModal.clientTemp + " is higher than 37.5 degrees after 2 readings, client was asked to go home and picked up by caregiver. Secondary caregiver attended to client until main caregiver arrived after.",
            crb5c_iscancelled: "Yes",
            crb5c_checkout_time: dayjs().utc().toISOString()
          }
          const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
          console.log(data);
          alert('Client was removed from room and remark has been added');
          this.clearmodal();
          this.checkedInModal.temp = "";
          this.tempModal.show = false;
          await this.fetchClientsInSession(this.sessionsPage.room);
          //this.$router.go()
        }
      }
      else if (index == 2) {
        const ans = confirm("Are you sure to proceed?");
        if (ans) {
          const payload = {
            crb5c_reportforsession: "Temperature recorded " + this.tempModal.clientTemp + " is higher than 37.5 degrees after 2 readings, client was asked to go home and picked up by caregiver. Main caregiver was on site and picked up the client immediately.",
            crb5c_checkout_time: dayjs().utc().toISOString(),
            crb5c_iscancelled: "Yes"
          }
          const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
          console.log(data);
          alert('Client was removed from room and remark has been added');
          this.clearmodal();
          this.checkedInModal.temp = "";
          this.tempModal.show = false;
          await this.fetchClientsInSession(this.sessionsPage.room);
          //this.$router.go()
        }
      }
      // console.log(session_id)
      // console.log(index)
    },
    async closeModal() {
      this.highTempModal.show = false;
      this.emergencyModal.show = false;
      this.crHighTempModal.show = false;
    },
    async clearmodal() {
      this.checkedInModal = {
        show: false,
        sessions: [],
        sessions_today: [],
        client_name: null,
        client_id: null,
        caregivers: []
      };
      this.adhoclistModal = {
        show: false,
        pick: false,
        adSessionid: null,
        idClient: null,
        adhocsessions: [],
      };
      this.highTempModal = {
        show: false
      }
      this.emergencyModal = {
        show: false,
        areaInput: null,
        sessID: null
      }
    },
    async showClient(cara_id, is_cara_id = true) {
      console.log('cara id =', cara_id);
      console.log(is_cara_id);
      if (!cara_id) {
        return;
      }
      let {
        data: { membershipId, user_id },
      } = await axios.get(is_cara_id ? `/api/cara/${cara_id}` : `/api/nric/${cara_id?.toUpperCase?.()}`);
      if (!membershipId && !user_id) {
        if (is_cara_id) {
          alert("Client not in CARA database");

        } else {
          alert("Client not in FOW database");
        }
        //client not found;
        return;
      }

      let paramObj = {
        $select: "crb5c_no",
        $expand:
          "crb5c_fow_caregiver_client_crb5c_FOW_Cust($select=crb5c_name)",
        $filter: is_cara_id ? `crb5c_cara_id eq '${membershipId}'` : `crb5c_fow_customerid eq '${user_id}'`,
      };
      console.log(user_id)

      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `/crb5c_fow_customers?${params.toString()}`
      );
      let client = clientData.value[0];
      console.log('client: ', client)
      if (!client) {
        //show error, client not found
        alert("Client not in FOW database");
        return;
      }
      let client_id = client.crb5c_fow_customerid;
      let client_name = client.crb5c_no;
      let caregivers = client.crb5c_fow_caregiver_client_crb5c_FOW_Cust.map(
        (cg) => {
          return {
            id: cg.crb5c_fow_caregiverid,
            name: cg.crb5c_name,
          };
        }
      );
      // console.log(client)
      // console.log(caregivers)
      let paramObj2 = {
        $select: "crb5c_room,crb5c_checkin_time,crb5c_checkout_time",
        $expand:
          "crb5c_session($select=crb5c_date,crb5c_duration,crb5c_sessionname)",
        $filter: `crb5c_client/crb5c_fow_customerid eq '${client_id}'`,
      };

      let params2 = new URLSearchParams(paramObj2);
      let { data: sessionData } = await this.$store.state.axios.get(
        `/crb5c_fowsessionattendances?${params2.toString()}`
      );

      let sessions = sessionData.value.map((s) => {
        return {
          id: s.crb5c_fowsessionattendanceid,
          date: s.crb5c_session?.crb5c_date,
          checkedIn: !!s.crb5c_checkin_time,
          checkedOut: !!s.crb5c_checkout_time,
          session_name: s.crb5c_session?.crb5c_sessionname,
          session_id: s.crb5c_session?.crb5c_fow_sessionid,
          duration: s.crb5c_session?.crb5c_duration,
          room: this.$store.state.rooms[s.crb5c_room]
        };
      });

      let sessions_today_all = sessions.filter((s) =>
        dayjs(s.date).tz(defaultTimezone).isToday()
      );

      let sessions_today = sessions_today_all.filter(s => !s.checkedIn);
      let sessions_today_checkout = sessions_today_all.filter(s => s.checkedIn && !s.checkedOut);
      this.checkedInModal.client_id = client_id;
      this.checkedInModal.client_name = client_name;
      this.checkedInModal.sessions = sessions;

      this.checkedInModal.sessions_today_all = sessions_today_all;
      console.log('CHECKOUT:', sessions_today_checkout)
      if (!this.checkOutMode && sessions_today_checkout.length) {
        console.log("running")
        this.promptwishtocheckout();
      }
      this.checkedInModal.sessions_today = sessions_today;
      this.checkedInModal.sessions_today_checkout = sessions_today_checkout;
      this.checkedInModal.sessions_today_checkout?.[0]?.id;




      if (sessions_today.length === 1) {
        let session_name = sessions_today_all[0].session_name;
        let session_duration = sessions_today_all[0].duration;
        let session_time = sessions_today_all[0].date;
        let session_endTime = dayjs(session_time).add(session_duration, 'minute');
        // console.log(dayjs(session_time))                 
        // console.log(dayjs(session_time).diff(dayjs(),'minute'))
        // console.log(session_endTime.diff(dayjs(), 'minute'))
        this.checkedInModal.sess_name = session_name;
        this.checkedInModal.sessDuration = session_duration;
        this.checkedInModal.sessTime = session_time;
        // this.checkedInModal.id = sessions_today_all[0].id;


        if (dayjs(session_time).diff(dayjs(), 'minute') <= 60 && session_endTime.diff(dayjs(), 'minute') > 0) {
          this.checkedInModal.validCheckinTime = "valid";
        }
        else if (dayjs(session_time).diff(dayjs(), 'minute') > 60 && session_endTime.diff(dayjs(), 'minute') > 0) {
          this.checkedInModal.validCheckinTime = "early";
        }
        else {
          this.checkedInModal.validCheckinTime = "late";
        }
      }

      this.checkedInModal.show = true;
      this.checkedInModal.caregivers = caregivers.map(c => {
        return { ...c, isChecked: false }
      });
      this.manualCheckedInModal.id = null;
      this.manualCheckedInModal.show = false;
      console.log({ client_id, client_name, caregivers, sessions });

      let paramObj3 = {
        $select: "crb5c_client_status",
        $filter: `crb5c_fow_customerid eq '${client_id}'`
      };
      let params3 = new URLSearchParams(paramObj3);
      let { data: clientStatus } = await this.$store.state.axios.get(
        `/crb5c_fow_customers?${params3.toString()}`
      );
      this.checkedInModal.clientStatus = clientStatus.value[0].crb5c_client_status;
    },
    promptwishtocheckout() {
      const result = confirm('Client is already checked-in, do you wish to CHECK-OUT');
      if (result) {
        this.checkOutMode = true;
        console.log('proceed id:', this.manualCheckedInModal.id)
        this.checkedInModal.show = false;
        this.fromCheckIn = true;
        console.log('checkoutmode', this.checkoutmode)
        this.$nextTick(() => {
          this.showClient(this.manualCheckedInModal.id, false);
        });
      }
    },
    closefilterdiv() {
      this.isFilter = false;
    },
    async updateCaregivertempModal(session_id) {
      this.tempModal.show = false;
      let paramObj = {
        $expand: `crb5c_client,crb5c_session,crb5c_caregiver,crb5c_caregiver2,crb5c_caregiver3,crb5c_caregiver4`,
        $filter: `crb5c_fowsessionattendanceid eq '${session_id}'`
      };
      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(`/crb5c_fowsessionattendances?${params.toString()}`);
      let attendance_info = clientData.value;
      this.tempModal.activeClientSessionDetails = JSON.parse(JSON.stringify(attendance_info[0]));
      this.tempModal.attendanceId = attendance_info[0].crb5c_fowsessionattendanceid;

      const caregiversAndTemps = [];
      for (let i = 1; i <= this.tempModal; i++) {
        const currentIteration = i == 1 ? '' : i;
        if (attendance_info[0]['crb5c_caregiver' + currentIteration]) {
          caregiversAndTemps.push({
            id: attendance_info[0]['crb5c_caregiver' + currentIteration].crb5c_fow_caregiverid,
            name: attendance_info[0]['crb5c_caregiver' + currentIteration].crb5c_name,
            temp: attendance_info[0]['crb5c_caregiver' + currentIteration + '_temp'],
            tempOut: attendance_info[0]['crb5c_caregiver' + currentIteration + '_out_temp'],
          })
        }
      }
      this.tempModal.caregiversAndTemps = caregiversAndTemps;
    },
    async confirmCheckinAlert(session_id) {
      if (this.selectedCaregivers == 0) {
        console.log(1)
        const alert2 = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Pick Caregiver!',
            message: '<strong>Client is about to checkin without a caregiver?</strong>',
            buttons: [
              {
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Proceed',
                id: 'confirm-button',
                handler: () => {
                  this.successCheckin(session_id, false);
                },
              },
            ],
          });
        return alert2.present();
      }
      else {
        this.successCheckin(session_id, true);
      }
    },
    async successCheckin(session_id, caregiverPresent) {
      if (!caregiverPresent) {
        const payload = {
          crb5c_checkin_time: dayjs().utc().toISOString(),
          //crb5c_temperature: this.checkedInModal.temp
        }
        const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
        console.log(data);
        this.clearmodal()
        alert('Check-in successful');
      } else {
        // console.log("runCheckinAlert2")
        const payload = {
          crb5c_checkin_time: dayjs().utc().toISOString(),
          //crb5c_temperature: this.checkedInModal.temp
        }
        for (let i = 0; i < this.selectedCaregivers.length && i < 4; i++) {
          payload[`crb5c_caregiver${i ? i + 1 : ''}@odata.bind`] = `/crb5c_fow_caregivers(${this.selectedCaregivers[i].id})`
        }
        const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
        console.log(data);
        this.clearmodal()
        alert('Check-in successful');
      }
    },
    async failedCheckIn(session_id) {
      const payload = {
        crb5c_sessionreport: "Client was not allowed to check in due to high temperature, recorded temperature was : " + this.checkedInModal.temp
      }
      const { data } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
      console.log(data);
      this.clearmodal()
      alert('Client Was not checked-in!, remark has been added');
    },

    async login() {
      try {
        await this.msalApp.loginRedirect({
          scopes: [`https://${this.logindata.DATAVERSE_DOMAIN}/.default`],
          redirectUri: location.origin + "/home",
        });
        !this.$store.state.msalApp && this.msalApp
          ? this.$store.commit("msalApp", this.msalApp)
          : console.log("not");
        await this.$root.prepareAxios();

        this.promptLogin = false;
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      return axios.get("/api/init").then(async (rsp) => {
        this.logindata = rsp.data;
        this.$store.commit("logindata", rsp.data);
        const msalConfig = {
          auth: {
            clientId: this.logindata.MS_APP_ID,
            authority: `https://login.microsoftonline.com/${this.logindata.TENANT_ID}`,
          },
        };
        console.log(msalConfig);
        let msalApp = (this.msalApp = new msal.PublicClientApplication(
          msalConfig
        ));
        let accounts = msalApp.getAllAccounts();
        console.log(accounts);
        if (accounts.length > 0) {
          msalApp.setActiveAccount(accounts[0]);
          this.$store.commit("msalApp", msalApp);
          await this.$root.prepareAxios();
        } else {
          msalApp.handleRedirectPromise().then(async () => {
            // Check if user signed in 
            const account = msalApp.getActiveAccount();
            this.$store.commit("msalApp", msalApp);
            await this.$root.prepareAxios();
            if (!account) {
              this.login();
            }
          }).catch(() => {
            this.login();
          });
          this.login();
        }

        return rsp;
      });
    },
    segmentChanged(sel) {
      this.subview = sel.details.value;
      // console.log(sel);
    },
    async showAlertConfrim() {
      const alert = await alertController.create({
        cssClass: "my-alert-class",
        header: "Complete",
        message: "Check-in is completed successfully",
        buttons: [
          {
            text: "YES",
            id: "confirm-button",
            handler: (blah) => {
              alert.dismiss();
              this.clearpage();
              console.log("Confirm Cancel:", blah);
            },
          },
        ],
      });
      return alert.present();
    },
    async addPicAlert() {
      const alert = await alertController.create({
        cssClass: "my-alert-class",
        header: "Submission Alert",
        message: "Add picture for report?",
        buttons: [
          {
            text: "YES",
            id: "confirm-button",
            handler: (blah) => {
              alert.dismiss();
              this.sessionReportModal.uploadPicModal = true;
              console.log("Confirm Cancel:", blah);
            },
          },
          {
            text: "NO",
            id: "no-button",
            handler: (blah) => {
              alert.dismiss();
              console.log("Confirm Cancel:", blah);
            },
          },
        ],
      });
      return alert.present();
    },
    async clearpage() {
      this.cr_name = "";
      this.cr_nric = "";
      this.cr_id = "";
      this.$router.go();
    },
    async changeCaregiver() {
      this.crHighTempModal.mode = true;

      let reserveCg = this.crHighTempModal.reserveCaregivers.filter((item) => {
        return (item.isChecked)
      })
      if (!reserveCg.length) {
        alert('Pick at least one caregiver!');
        return;
      }
      const result = confirm('You are about to pick the selected cregiver(s) as replacement');
      if (!result) {
        return;
      }

      this.crHighTempModal.subsCaregiver.push({
        id: reserveCg.id,
        name: reserveCg.name,
        isChecked: reserveCg.isChecked
      });
      console.log(this.crHighTempModal.subsCaregiver)
      const payload2 = {
        crb5c_reportforsession: "The initial caregiver recorded a high temperature during check-in, the caregiver was then substituted.",
      }
      for (let i = 0; i < 4; i++) {
        if (reserveCg[i]) {
          payload2[`crb5c_caregiver${i ? i + 1 : ''}@odata.bind`] = `/crb5c_fow_caregivers(${reserveCg[i].id})`
        }
        else {
          payload2[`crb5c_caregiver${i ? i + 1 : ''}@odata.bind`] = null
        }
      }
      const { data2 } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${this.crHighTempModal.session_id})`, payload2)
      console.log(data2);
      alert("Caregiver successfully updated!")
      await this.fetchClientsInSession(this.sessionsPage.room);
      this.updateCaregivertempModal(this.crHighTempModal.session_id)
      this.clearcrHighTempModal();
      this.crHighTempModal.totalCg = 0;
    },
    async newCaregiver() {
      const result = prompt('Enter the new Caregiver name');
      if (!result?.trim()) {
        return;
      }
      const payload = {
        crb5c_name: result.trim(),
        "crb5c_client@odata.bind": `/crb5c_fow_customers(${this.crHighTempModal.client_id})`
      }

      const { data } = await this.$store.state.axios.post('/crb5c_fow_caregivers', payload);
      console.log(data)
      this.crHighTempModal.reserveCaregivers.push({
        id: data.crb5c_fow_caregiverid,
        name: data.crb5c_name,
        isChecked: true
      });
      this.crHighTempModal.totalCg++;
    },
    async questionDiv(buttonVal) {
      if (buttonVal == 'next') {
        this.sessionReportModal.questionNum++;
      }
      else {
        this.sessionReportModal.questionNum--
      }
    },
    async submitSessionReport() {
      const payload = {}
      //function to format data and insert only if present
      let appendData = (key, value) => {
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        if (value) {
          payload[key] = value;
        }
      }
      appendData('crb5c_session@odata.bind', `crb5c_fowsessionattendances(${this.sessionReportModal.sessID})`)
      appendData('crb5c_mood', this[this.sessionReportModal.reportFormat]['moodOnArrival' + this.sessionReportModal.headerType]);
      appendData('crb5c_mood_end', this[this.sessionReportModal.reportFormat]['moodOnEnd' + this.sessionReportModal.headerType]);
      appendData('crb5c_behaviour', this[this.sessionReportModal.reportFormat]['behaviourOnArrival' + this.sessionReportModal.headerType]);
      appendData('crb5c_behaviour_end', this[this.sessionReportModal.reportFormat]['behaviourOnEnd' + this.sessionReportModal.headerType]);

      let activityArray = [];

      let addActivity = (obj, type) => {
        if (!obj.name) {
          return;
        }
        // console.log("Object ID: " + obj.type)
        // console.log(obj)
        if (obj.type != 3 && obj.type != 4) {
          let activityObj = { crb5c_physitype: type, crb5c_name: obj.name, crb5c_addremarks: obj.remark, 'crb5c_Client@odata.bind': `crb5c_fow_customers(${this?.sessionReportModal?.clientDetail?._crb5c_client_value})` };
          let keyvalues = {
            behaviour: 'crb5c_behaviour',
            mood: 'crb5c_mood',
            thought: 'crb5c_thoughts',
            participation: 'crb5c_participation',
            alertness: 'crb5c_levelofalertness',
            attention: 'crb5c_attentionspan',
          }
          for (let key in keyvalues) {
            let val = obj[key].join(',');
            let inskey = keyvalues[key];
            if (!val) {
              continue;
            }
            activityObj[inskey] = val
          }
          activityArray.push(activityObj);
        }
        else if (obj.type == 3 || obj.type == 4) {
          // console.log("runing namaste")
          let activityObj = { crb5c_physitype: type, crb5c_name: obj.name, crb5c_addremarks: obj.remark, 'crb5c_Client@odata.bind': `crb5c_fow_customers(${this?.sessionReportModal?.clientDetail?._crb5c_client_value})` };
          let keyvalues2 = {
            facialBegin: 'crb5c_namastebeginfacialexpression',
            eyeContactBegin: 'crb5c_namastebegineyecontact',
            interestBegin: 'crb5c_namastebegininterestincommunicating',
            postureBegin: 'crb5c_namastebeginbodyposture',
            behaviourBegin: 'crb5c_namastebeginbehaviour',
            moodBegin: 'crb5c_namastebeginmood',
            facialEnd: 'crb5c_namasteendfacialexpression',
            eyeContactEnd: 'crb5c_namasteendeyecontact',
            interestEnd: 'crb5c_namasteendinterestincommunicating',
            postureEnd: 'crb5c_namasteendbodyposture',
            behaviourEnd: 'crb5c_namasteendbehaviour',
            moodEnd: 'crb5c_namasteendmood',
          }
          for (let key in keyvalues2) {
            let val = obj[key].join(',');
            let inskey = keyvalues2[key];
            if (!val) {
              continue;
            }
            activityObj[inskey] = val
          }
          activityArray.push(activityObj);
        }
      }
      addActivity(this[this.sessionReportModal.reportFormat].activities.one, this[this.sessionReportModal.reportFormat].activities.one.type);
      addActivity(this[this.sessionReportModal.reportFormat].activities.two, this[this.sessionReportModal.reportFormat].activities.two.type);
      addActivity(this[this.sessionReportModal.reportFormat].activities.three, this[this.sessionReportModal.reportFormat].activities.three.type);
      addActivity(this[this.sessionReportModal.reportFormat].activities.four, this[this.sessionReportModal.reportFormat].activities.four.type);


      if (activityArray.length) {
        console.log('activity array:', activityArray)
        payload['crb5c_crb5c_fowsessionactivity_SessionReport_cr'] = activityArray
      }

      console.log('activity array:', activityArray)
      console.log('payload ', payload);
      const { data } = await this.$store.state.axios.post('/crb5c_fow_session_reports', payload);
      console.log(data)

      // const payload2 = { crb5c_reportcompletion: "Done" }
      // const { data2 } = this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${this.sessionReportModal.sessID})`, payload2)
      // console.log(data2);
      // console.log("room id",this.sessionsPage.room);
      alert('Session Report uploaded, please check the attendance page for the report')
      this.$refs.mildModal.$el.dismiss();
      await this.$nextTick();
      this.$forceUpdate();
      // console.log('force updated', this.allAttendees);
      // console.log(this.$refs.mildModal, "modal name")
      //this.sessionReportModal.activityModal = false;

      await this.fetchClientsInSession(this.sessionsPage.room);
      this.modalReset();
    },
    clearSessionReportModal() {
      this.sessionReportModal.activity = null;
    },
    clearReportModal() {
      this.sessionReportModal.activity = null;
      this.sessionReportModal.activityTitle = null;
      this.sessionReportModal.questionNum = 0;
      this.sessionReportModal.show = false;
      this.sessionReportModal.showIndividual = false;
      this.sessionReportModal.clientDetail = null;
      this.sessionReportModal.reportSessionType = false;
      this.sessionReportModal.sessID = null;
      this.sessionReportModal.activityModal = false;
    },
    clearcrHighTempModal() {
      this.crHighTempModal.show = false;
      this.crHighTempModal.reserveCaregivers = [];
      this.crHighTempModal.client_id = null;
      this.crHighTempModal.session_id = null;
      this.crHighTempModal.subsCaregiver = [];
    },
    async addNewActivity() {
      console.log("running")
      let activity_name = prompt('Please enter new activity name');
      if (activity_name) {
        let yes = confirm('Add image for activity?');
        if (yes) {
          this.sessionReportModal.uploadPicModal = true;
        }
        else {
          this.uploadActivity();
        }

        // const alert = await alertController
        //   .create({
        //     cssClass: 'my-custom-class',
        //     message: '<strong>Add image for activity?</strong>',
        //     buttons: [
        //       {
        //         text: 'No',
        //         role: 'cancel',
        //         cssClass: 'secondary',
        //         id: 'cancel-button',
        //         handler: blah => {
        //           this.failedCheckIn(session_id)
        //           console.log('Confirm Cancel:', blah)
        //         },
        //       },
        //       {
        //         text: 'Proceed',
        //         id: 'confirm-button',
        //         handler: blah => {
        //           this.confirmCheckinAlert(session_id)
        //           console.log('Confirm Okay', blah)
        //         },
        //       },
        //     ],
        //   });
        // return alert.present();

      }

    },
    async uploadActivity() {
      console.log('show image url', this.myFiles, this.myFiles2)

      const reader = new FileReader()

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        console.log(rawImg);
      }
      reader.readAsDataURL(this.myFiles);
      console.log(this.myFiles)
      let base = reader.readAsBinaryString(this.myFiles)

      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   this.userObject.imgPersonal = e.target.result;
      // };

      console.log("file object", this.myFiles2, base);
      this.sessionReportModal.uploadPicModa = false;
    },
    checkActivity4MildRadio() {
      this.mildReport.activities.four.type = this.sessionReportModal?.MildActivity4Choice?.id;
      //console.log(this.mildReport.activities.four.type)
    },
    checkActivity1ModerateRadio() {
      this.moderateReport.activities.one.type = this.sessionReportModal?.ModerateActivity1Choice?.id;
      //console.log(this.moderateReport.activities.one.type)
    },
    checkActivity2ModerateRadio() {
      this.moderateReport.activities.two.type = this.sessionReportModal?.ModerateActivity2Choice?.id;
      //console.log(this.moderateReport.activities.two.type)
    },
    checkActivity3ModerateRadio() {
      this.moderateReport.activities.three.type = this.sessionReportModal?.ModerateActivity3Choice?.id;
      //console.log(this.moderateReport.activities.three.type)
    },
    checkActivity4ModerateRadio() {
      this.moderateReport.activities.four.type = this.sessionReportModal?.ModerateActivity4Choice?.id;
      //console.log(this.moderateReport.activities.four.type)
    },
    closeModalLink() {
      this.navigateToAttendanceModal.show = false;
    },
    // async getData() {

    //   console.log(this[this.sessionReportModal.reportFormat].activities.one.thought.join(','))

    //   let paramObj = {
    //     $select: "crb5c_mood",
    //     $filter: `crb5c_name eq 'test'`
    //   };

    //   let params = new URLSearchParams(paramObj);
    //   let { data: clientData } = await this.$store.state.axios.get(
    //     `/crb5c_fowsessionactivities?${params.toString()}`
    //   );
    //   console.log(clientData)
    // },
    async updateActivityList() {
      let paramObj = {
        $select: "crb5c_name, crb5c_activityimage, crb5c_activitytype",
      };
      let params = new URLSearchParams(paramObj);
      let { data: activityData } = await this.$store.state.axios.get(
        `crb5c_activityprofiles/?${params.toString()}`
      );

      this.sessionReportModal.activityList = activityData.value;
      // console.log(this.sessionReportModal.activityList)

      this.sessionReportModal.physicalActivities = this.sessionReportModal.activityList.filter((item) => {
        return (item.crb5c_activitytype.includes('0'))
      })
      this.sessionReportModal.psysioActivities = this.sessionReportModal.activityList.filter((item) => {
        return (item.crb5c_activitytype.includes('1'))
      })
      this.sessionReportModal.cognitiveActivities = this.sessionReportModal.activityList.filter((item) => {
        return (item.crb5c_activitytype.includes('2'))
      })
      this.sessionReportModal.namasteActivities = this.sessionReportModal.activityList.filter((item) => {
        return (item.crb5c_activitytype.includes('3'))
      })
      this.sessionReportModal.individualActivities = this.sessionReportModal.activityList.filter((item) => {
        return (item.crb5c_activitytype.includes('4'))
      })


    },
    async confirmExitModal() {
      let proceed = confirm('You are about to exit the session report')
      if (proceed) {
        this.modalReset()
      }
    },
    async modalReset() {
      this.sessionReportModal.ModerateActivity1Choice = null;
      this.sessionReportModal.ModerateActivity2Choice = null;
      this.sessionReportModal.ModerateActivity3Choice = null;
      this.sessionReportModal.ModerateActivity4Choice = null;
      this.activity4Mild.isChecked = false;
      this.activity1Moderate.isChecked = false;
      this.activity2Moderate.isChecked = false;
      this.activity3Moderate.isChecked = false;
      this.activity4Moderate.isChecked = false;
      this.sessionReportModal.MildActivity4Choice = null;
      this.mildReport.behaviourOnArrivalMild = [];
      this.mildReport.moodOnArrivalMild = [];
      this.mildReport.behaviourOnEndMild = [];
      this.mildReport.moodOnEndMild = [];
      this.moderateReport.behaviourOnArrivalModerate = [];
      this.moderateReport.moodOnArrivalModerate = [];
      this.moderateReport.behaviourOnEndModerate = [];
      this.moderateReport.moodOnEndModerate = [];
      this.sessionReportModal.reportType = null;

      this.mildReport.activities = {
        one: {
          name: "",
          type: 0,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,

        },
        two: {
          name: "",
          type: 1,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
        },
        three: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
        },
        four: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
        }
      };

      this.moderateReport.activities = {
        one: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
          facialBegin: [],
          eyeContactBegin: [],
          interestBegin: [],
          postureBegin: [],
          behaviourBegin: [],
          moodBegin: [],
          facialEnd: [],
          eyeContactEnd: [],
          interestEnd: [],
          postureEnd: [],
          behaviourEnd: [],
          moodEnd: [],

        },
        two: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
          facialBegin: [],
          eyeContactBegin: [],
          interestBegin: [],
          postureBegin: [],
          behaviourBegin: [],
          moodBegin: [],
          facialEnd: [],
          eyeContactEnd: [],
          interestEnd: [],
          postureEnd: [],
          behaviourEnd: [],
          moodEnd: [],

        },
        three: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
          facialBegin: [],
          eyeContactBegin: [],
          interestBegin: [],
          postureBegin: [],
          behaviourBegin: [],
          moodBegin: [],
          facialEnd: [],
          eyeContactEnd: [],
          interestEnd: [],
          postureEnd: [],
          behaviourEnd: [],
          moodEnd: [],
        },
        four: {
          name: "",
          type: null,
          behaviour: [

          ],
          mood: [

          ],
          thought: [

          ],
          participation: [

          ],
          alertness: [

          ],
          attention: [

          ],
          remark: null,
          facialBegin: [],
          eyeContactBegin: [],
          interestBegin: [],
          postureBegin: [],
          behaviourBegin: [],
          moodBegin: [],
          facialEnd: [],
          eyeContactEnd: [],
          interestEnd: [],
          postureEnd: [],
          behaviourEnd: [],
          moodEnd: [],
        }
      };
      // for (let x = 0; x < 4; x++) {
      //   console.log(num[x])

      // this.mildReport.activities.num[x].behaviour = {};
      // this.mildReport.activities.num[x].mood = {};
      // this.mildReport.activities.num[x].participation = {};
      // this.mildReport.activities.num[x].thought = {};
      // this.mildReport.activities.num[x].alertness = {};
      // this.mildReport.activities.num[x].attention = {};
      // this.mildReport.activities.num[x].attention = {};
      // this.mildReport.activities.num[x].attention = {};
      // this.mildReport.activities.num[x].name = "";
      // this.mildReport.activities.num[x].type = "";

      // this.moderateReport.activities.num[x].behaviour = {};
      // this.moderateReport.activities.num[x].mood = {};
      //   this.moderateReport.activities.num[x].participation = {};
      //   this.moderateReport.activities.num[x].thought = {};
      //   this.moderateReport.activities.num[x].alertness = {};
      //   this.moderateReport.activities.num[x].attention = {};
      //   this.moderateReport.activities.num[x].facialBegin = {};
      //   this.moderateReport.activities.num[x].facialEnd = {};
      //   this.moderateReport.activities.num[x].interestBegin = {};
      //   this.moderateReport.activities.num[x].interestEnd = {};
      //   this.moderateReport.activities.num[x].eyeContactBegin = {};
      //   this.moderateReport.activities.num[x].eyeContactEnd = {};
      //   this.moderateReport.activities.num[x].postureBegin = {};
      //   this.moderateReport.activities.num[x].postureEnd = {};
      //   this.moderateReport.activities.num[x].moodBegin = {};
      //   this.moderateReport.activities.num[x].moodEnd = {};
      //   this.moderateReport.activities.num[x].behaviourBegin = {};
      //   this.moderateReport.activities.num[x].behaviourEnd = {};
      //   this.moderateReport.activities.num[x].name = "";
      //   this.moderateReport.activities.num[x].type = "";
      // }


    },
    checkActivity1Name() {
    },
  },
});
</script>
