import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import CheckOutPage from '../views/CheckOutPage.vue'
import store from '../store/store'


const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/checkOut',
    name: 'CheckOut',
    component: CheckOutPage,
    beforeEnter: () => {
      if (!store.state.axios) {
        return '/home'
      }
    },
  }, 
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
