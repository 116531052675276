<template>
  <ion-page>
    <ion-modal :swipe-to-close="true" :is-open="checkedOutModal.show">
      <ion-content>
        <div class="checked-in-modal-content-container">
          <h1 class="text-center">Hello <strong>{{ checkedOutModal.client_name }}</strong></h1>
          <div style="max-width: 100px; margin: auto">
              <ion-avatar class="avatarHolder">
                <ion-img src="/assets/images/user.png"></ion-img>
              </ion-avatar>
          </div>
          <div v-if="checkedOutModal.sessions_today_all.length">
            <h3>Confim Checkout?</h3>
          
            <ion-label>
            <h2>Session Name: <strong>{{checkedOutModal.sessions_today[0].session_name}}</strong></h2>
            </ion-label>
            <hr/>
            <ion-label>
            <h2>Check-in time: <strong>{{checkedOutModal.sessions_today[0].checkedIn}}</strong></h2>
            </ion-label>
            <hr/>
            <ion-button class="ToolbarBtn" @click="confirmCheckOut(checkedOutModal.sessions_today[0].id)">Confirm</ion-button>
          </div>
          <div v-else>Client is not checked-in</div>
        </div>
      </ion-content>
    </ion-modal>
    <ion-content :fullscreen="true">
      <ion-toolbar class="MainToolbar">
        <ion-title>Check-Out</ion-title>
      </ion-toolbar>

      <ion-header>
        <ion-toolbar class="NavToolbar">
          <div class="center">
            <ion-button class="ToolbarBtn">Manual Check-In</ion-button>
            <ion-button @click="checkoutPage">Check-in</ion-button>
          </div>
        </ion-toolbar>
      </ion-header>

      <div class="divBack">
        <div v-if="promptLogin">
          <ion-modal>
            <ion-content>
                <h2>Please login to continue</h2>
                <hr/>
                <ion-button @click="login">Login</ion-button>
            </ion-content>
          </ion-modal>

        </div>
        <ion-grid class="mainHolder">

          <div class="divHolder">
            <div class="info">
              <ion-card class="centerCard">
                <ion-card-content class="centerCard">
                    <ion-label class="scanLabel"><strong>Scan to Check-out</strong></ion-label>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
        </ion-grid>
        <div class="idInput">
          <ion-input
            type="text"
            autofocus="true"
            v-model="cara_id"
            class="scannerID"
          ></ion-input>
        </div>
        <div class="invisible">
          <ion-button @click="presentAlertConfirm" class="button_light"
            >Next</ion-button
          >
        </div>
        <div class="fixed">
          <ion-fab vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button color="danger" @click="openIncidentReportModal">
              <ion-icon :icon="i.flag"></ion-icon>
            </ion-fab-button>
          </ion-fab>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import {
  IonContent,
  IonModal,
  alertController,
  IonFab,
  IonAvatar,
  IonFabButton,
  IonLabel,
  IonInput,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonGrid,
  IonButton,
  IonIcon,

} from "@ionic/vue";
import * as allIcons from "ionicons/icons";
import { defineComponent } from "vue";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import isToday from "dayjs/plugin/isToday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
const defaultTimezone = "Asia/Singapore";
dayjs.tz.setDefault(defaultTimezone);

import axios from "axios";
import * as msal from "@azure/msal-browser";

export default defineComponent({
  name: "HomePage",
  computed: {
    ready() {
      return !this.promptLogin && this.logindata?.ready;
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonLabel,
    IonInput,
    IonModal,
    IonFabButton,
    IonPage,
    IonTitle,
    //IonCol,
    IonIcon,
    IonGrid,
    IonButton,
    IonToolbar,
    IonAvatar,
    // IonGrid,
    IonFab,
    // IonAlert,
    IonCard,
    IonCardContent,

  },
  data() {
    return {
      subview: "client",
      i: allIcons,
      cr_name: "",
      cr_nric: "",
      cara_id: "",
      logindata: { ready: false },
      msalApp: null,
      promptLogin: false,
      listener: null,
      scanholder: "",
      scanInProgress: false,
      checkedOutModal: {
        show: false,
        sessions: [],
        sessions_today: [],
        client_name: null,
        client_id: null,
        caregivers: [],
        temp: "",
      },
    };
  },
  watch: {
    cara_id() {
      this.showClient(this.cara_id);
    },
  },
  async mounted() {
    await this.init();
    this.listener = (ev) => {
      console.log(ev);
      this.scanholder += ev.key.length == 1 ? ev.key : "";
      if (!this.scanInProgress) {
        this.scanInProgress = true;
        setTimeout(() => {
          console.log(this.scanholder);
          this.scanInProgress = false;
          let URLbase = "https://cara.sg/safereturncards/";
          if (this.scanholder.indexOf(URLbase) === 0) {
            this.cara_id = this.scanholder.substring(URLbase.length);
          }
          console.log(this.cara_id);
          this.scanholder = "";
        }, 500);
      }
    };
    window.addEventListener("keypress", this.listener);
    this.showClient(this.cara_id);
  },
  methods: {
    async checkinPage(){
        console.log("clicked")
    },
    async confirmCheckOut(session_id) {
      const payload = {
        crb5c_checkout_time: dayjs().utc().toISOString(),
      }

      const {data} = await this.$store.state.axios.patch(`/crb5c_fowsessionattendances(${session_id})`, payload)
      console.log(data);
      this.checkedInModal = {
        show: false,
        sessions: [],
        sessions_today: [],
        client_name: null,
        client_id: null,
        caregivers: []
      };
      this.cara_id = "";
    }, 

    async addCaregiver(client_id){
      const result = prompt('Enter the new Caregiver name');
      if (!result?.trim()) {
        return;
      }
      const payload = {
        crb5c_name: result.trim(),
        "crb5c_client@odata.bind": `/crb5c_fow_customers(${client_id})`
      }

      const {data} = await this.$store.state.axios.post('/crb5c_fow_caregivers', payload);
      this.checkedInModal.caregivers.push({
        id: data.crb5c_fow_caregiverid,
        name: data.crb5c_name,
        isChecked: true
      });

    },
    async showClient(cara_id) {
      console.log('lol')
      let {
        data: { membershipId },
      } = await axios.get(`/api/cara/${cara_id}`);
      if (!membershipId) {
        //client not found;
        return;
      }
      let paramObj = {
        $select: "crb5c_no",
        $expand:
          "crb5c_fow_caregiver_client_crb5c_FOW_Cust($select=crb5c_name)",
        $filter: `crb5c_cara_id eq '${membershipId}'`,
      };

      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `/crb5c_fow_customers?${params.toString()}`
      );
      let client = clientData.value[0];
      if (!client) {
        //show error, client not found
        return;
      }
      let client_id = client.crb5c_fow_customerid;
      let client_name = client.crb5c_no;
      let caregivers = client.crb5c_fow_caregiver_client_crb5c_FOW_Cust.map(
        (cg) => {
          return {
            id: cg.crb5c_fow_caregiverid,
            name: cg.crb5c_name,
          };
        }
      );
      let paramObj2 = {
        $select: "crb5c_room,crb5c_checkin_time",
        $expand:
          "crb5c_session($select=crb5c_date,crb5c_duration,crb5c_sessionname)",
        $filter: `crb5c_client/crb5c_fow_customerid eq '${client_id}'`,
      };

      let params2 = new URLSearchParams(paramObj2);
      let { data: sessionData } = await this.$store.state.axios.get(
        `/crb5c_fowsessionattendances?${params2.toString()}`
      );

      let sessions = sessionData.value.map((s) => {
        return {
          id: s.crb5c_fowsessionattendanceid,
          date: s.crb5c_session?.crb5c_date,
          checkedIn: !! s.crb5c_checkin_time,
          session_name: s.crb5c_session?.crb5c_sessionname,
          session_id: s.crb5c_session?.crb5c_fow_sessionid,
          duration: s.crb5c_session?.crb5c_duration,
          room: this.$store.state.rooms[s.crb5c_room]
        };
      });

      let sessions_today_all = sessions.filter((s) =>
        dayjs(s.date).tz(defaultTimezone).isToday()
      );
      let sessions_today = sessions_today_all.filter(s=>!s.checkedIn);

      this.checkedOutModal.client_id = client_id;
      this.checkedOutModal.client_name = client_name;
      this.checkedOutModal.sessions = sessions;
      this.checkedOutModal.sessions_today_all = sessions_today_all;
      this.checkedOutModal.sessions_today = sessions_today;

      this.checkedOutModal.show = true;
      console.log({ client_id, client_name, caregivers, sessions });
    },
    async login() {
      try {
        await this.msalApp.loginPopup({
          scopes: [`https://${this.logindata.DATAVERSE_DOMAIN}/.default`],
          redirectUri: location.origin,
        });
        !this.$store.state.msalApp && this.msalApp
          ? this.$store.commit("msalApp", this.msalApp)
          : console.log("not");
        await this.$root.prepareAxios();

        this.promptLogin = false;
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      axios.get("/api/init").then(async (rsp) => {
        this.logindata = rsp.data;
        this.$store.commit("logindata", rsp.data);
        const msalConfig = {
          auth: {
            clientId: this.logindata.MS_APP_ID,
            authority: `https://login.microsoftonline.com/${this.logindata.TENANT_ID}`,
          },
        };
        let msalApp = (this.msalApp = new msal.PublicClientApplication(
          msalConfig
        ));
        let accounts = msalApp.getAllAccounts();
        if (accounts.length > 0) {
          msalApp.setActiveAccount(accounts[0]);
          this.$store.commit("msalApp", msalApp);
          await this.$root.prepareAxios();
        } else {
          this.promptLogin = true;
          this.login();
        }
      });
    },
    async presentAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-alert-class",
        header: "Client Confirmation",
        message: "Are the details correct?",
        inputs: [
          {
            id: "name2-id",
            value: "Name: Marry",
            placeholder: "Placeholder 2",
          },
          {
            value: "Age: 64",
            placeholder: "",
          },
          {
            value: "NRIC: 8965745632",
            placeholder: "",
          },
        ],
        buttons: [
          {
            text: "NO",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: (blah) => {
              console.log("Confirm Cancel:", blah);
            },
          },
          {
            text: "YES",
            id: "confirm-button",
            handler: (blah) => {
              alert.dismiss();
              this.showAlertConfrim();
              console.log("Confirm Cancel:", blah);
            },
          },
        ],
      });
      return alert.present();
    },
    async showAlertConfrim() {
      const alert = await alertController.create({
        cssClass: "my-alert-class",
        header: "Complete",
        message: "Check-in is completed successfully",
        buttons: [
          {
            text: "YES",
            id: "confirm-button",
            handler: (blah) => {
              alert.dismiss();
              this.clearpage();
              console.log("Confirm Cancel:", blah);
            },
          },
        ],
      });
      return alert.present();
    },
    async clearpage() {
      this.cr_name = "";
      this.cr_nric = "";
      this.cr_id = "";
      this.$router.go();
    },
  },
});
</script>
