import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      msalApp: null,
      logindata: null,
      axios: null,
      rooms:{}
    }
  },
  mutations: {
    msalApp(state, payload) {
       state.msalApp = payload;
    },
    logindata(state, payload) {
        state.logindata = payload;
    },
    axios(state, payload) {
        state.axios = payload;
    },
    rooms(state, payload) {
      state.rooms = payload;
    }
  },
  actions: {
    
  }
})

export default store;